import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({ nameLocal: '', email: '', phone: '' });

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get('/api/contacts');
      setContacts(response.data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      if (selectedContact) {
        await axios.put(`/api/contacts/${selectedContact._id}`, formData);
      } else {
        await axios.post('/api/contacts/create', formData);
      }
      fetchContacts();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving contact:', error);
    }
  };

  const handleEdit = (contact) => {
    setSelectedContact(contact);
    setFormData({ nameLocal: contact.nameLocal, email: contact.email, phone: contact.phone });
    setOpenDialog(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/contacts/${id}`);
      fetchContacts();
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };

  const handleAddNew = () => {
    setSelectedContact(null);
    setFormData({ nameLocal: '', email: '', phone: '' });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedContact(null);
    setFormData({ nameLocal: '', email: '', phone: '' });
  };

  return (
    <div >
      <Typography variant="h4" gutterBottom className="ppp">
        Contacts Management
      </Typography>
      <Button variant="contained" color="primary" onClick={handleAddNew} style={{ marginBottom: '20px' }}>
        Add New Contact
      </Button>
      <Card style={{ padding: '20px' }}>
        <Table>
          <TableBody>
            {contacts.map((contact) => (
              <TableRow key={contact._id}>
                <TableCell>{contact.nameLocal}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.phone}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleEdit(contact)}>
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(contact._id)}
                    style={{ marginLeft: '10px' }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
  <DialogTitle id="form-dialog-title">{selectedContact ? 'Edit Contact' : 'Add New Contact'}</DialogTitle>
  <DialogContent>
    <TextField
      label="Name"
      name="nameLocal"
      value={formData.nameLocal || ''}
      onChange={handleInputChange}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Email"
      name="email"
      value={formData.email || ''}
      onChange={handleInputChange}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Phone"
      name="phone"
      value={formData.phone || ''}
      onChange={handleInputChange}
      fullWidth
      margin="normal"
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="secondary">
      Cancel
    </Button>
    <Button onClick={handleSave} color="primary">
      Save
    </Button>
  </DialogActions>
</Dialog>
    </div>
  );
}

export default Contacts;