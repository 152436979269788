import React, { useState, useEffect } from 'react';
import CampaignEditor from '../components/CampaignEditor';
import CampaignFilters from '../components/CampaignFilters';
import { listCampaigns, createCampaign, updateCampaign, getDeviceCountByCampaign } from '../services/api'; // Импорт на правилните методи
import { getPublicPath } from '../utils/pathUtils';
import axios from 'axios';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Grid,
  Card
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { set } from 'mongoose';

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [deviceCounts, setDeviceCounts] = useState({});
  const [dynamicSummaries, setDynamicSummaries] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isEditorOpen, setEditorOpen] = useState(false);
  const [filters, setFilters] = useState({
    //  channel: '',
    // posTypeNew: '',
    //typeIR: '',
    //kaName: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tradeSegmentOptions, setTradeSegmentOptions] = useState([]);
  const [posTypeNewOptions, setPosTypeNewOptions] = useState([]);
  const [kaNameOptions, setKaNameOptions] = useState([]);
  const [mannedOptions, setMannedOptions] = useState([]);
  const [placementNewOptions, setPlacementNewOptions] = useState([]);
  const [tireOptions, setTireOptions] = useState([]);
  const [screenTypeOptions, setScreenTypeOptions] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [filterIsActive, setFilterIsActive] = useState(false);
  const [filterIsValid, setFilterIsValid] = useState(true);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' или 'desc'

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);

    const sorted = [...filteredCampaigns].sort((a, b) => {
      const aValue = a[column] || '';
      const bValue = b[column] || '';

      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredCampaigns(sorted);
  };
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);


  const fetchCampaigns = async () => {
    try {
      const data = await listCampaigns();
      const currentDate = new Date();

      // Филтриране на активните кампании по дата и статус
      const validCampaigns = data.filter((campaign) => {
        const validFrom = new Date(campaign.startDate);
        const validTo = new Date(campaign.endDate);

        return (
          campaign.isActive &&
          validFrom <= currentDate &&
          validTo >= currentDate
        );
      });

      setCampaigns(data);
      setFilteredCampaigns(validCampaigns);
      // Уникални опции за филтри
      const tradeSegments = new Set();
      const posTypeNew = new Set();
      const kaName = new Set();
      const manned = new Set();
      const placementNew = new Set();
      const category = new Set();
      const tire = new Set();
      const screenType = new Set();

      validCampaigns.forEach((campaign) => {
        (campaign.tradeSegment || []).forEach((segment) => tradeSegments.add(segment));
        (campaign.posTypeNew || []).forEach((posType) => posTypeNew.add(posType));
        (campaign.kaName || []).forEach((ka) => kaName.add(ka));
        (campaign.manned || []).forEach((man) => manned.add(man));
        (campaign.placementNew || []).forEach((placement) => placementNew.add(placement));
        if (campaign.category && campaign.category.name) {
          category.add(campaign.category.name); // Добавяме името на категорията
        }
        (campaign.tire || []).forEach((tir) => tire.add(tir));
        (campaign.screenType || []).forEach((screen) => screenType.add(screen));
      });

      // Настройка на опции за филтри
      setTradeSegmentOptions(Array.from(tradeSegments));
      setPosTypeNewOptions(Array.from(posTypeNew));
      setKaNameOptions(Array.from(kaName));
      setMannedOptions(Array.from(manned));
      setPlacementNewOptions(Array.from(placementNew));
      setCategoryOptions(Array.from(category));
      setTireOptions(Array.from(tire));
      setScreenTypeOptions(Array.from(screenType));
      // Извличане на броя устройства за всяка кампания
      const counts = {};
      const summaries = {};
      for (const campaign of data) {
        // console.log('campaign:', campaign.keyVisual); // Проверка за кампания
        try {
          counts[campaign._id] = await getDeviceCountByCampaign(campaign._id);
          summaries[campaign._id] = formatDynamicSummary(campaign);
        } catch {
          counts[campaign._id] = 0; // Ако има грешка, по подразбиране е 0
          summaries[campaign._id] = 'N/A';
        }
      }
      setDeviceCounts(counts);
      setDynamicSummaries(summaries);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };
  const fetchFilteredCampaigns = async () => {
    try {
      const { data } = await axios.get('/api/campaigns/filtered', {
        params: filters,
      });
      setCampaigns(data);
    } catch (error) {
      console.error('Error fetching filtered campaigns:', error);
    }
  };


  const formatDynamicSummary = (campaign) => {
    const fields = [
      'tradeSegment',
      'placementNew',
      'posTypeNew',
      'kaName',
      'manned',
      'category',
      'tire',
      'screenType',
    ];
    return fields
      .map((field) => {
        const value = campaign[field];
        return value && value.length > 0 ? `${field}: ${value.join(', ')}` : null;
      })
      .filter(Boolean)
      .join(' | ');
  };


  const handleSave = async (campaign) => {
    try {
      if (campaign._id) {
        await updateCampaign(campaign._id, campaign); // Използваме правилния метод за обновяване
      } else {        
        await createCampaign(campaign); // Използваме правилния метод за създаване
      }
      fetchCampaigns(); // Обновяваме списъка след запис
      setEditorOpen(false); // Затваряме редактора
    } catch (error) {
      console.error('Error saving campaign:', error);
    }
  };

  const handleEdit = (campaign) => {
    setSelectedCampaign(campaign);
    setEditorOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/campaigns/${id}`);
      fetchCampaigns();
    } catch (error) {
      console.error('Error deleting campaign:', error);
    }
  };

  const handleAddNew = () => {
    setSelectedCampaign(null);
    setEditorOpen(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const matchesSearch = (campaign, term) => {
    const lowerCaseTerm = term.toLowerCase();
    return Object.values(campaign).some((value) => {
      if (value === null || value === undefined) {
        return false; // Пропускаме стойности null или undefined
      } else if (typeof value === 'string') {
        return value.toLowerCase().includes(lowerCaseTerm);
      } else if (Array.isArray(value)) {
        return value.some(
          (item) =>
            item !== null &&
            item !== undefined &&
            item.toString().toLowerCase().includes(lowerCaseTerm)
        );
      } else if (typeof value === 'object') {
        return matchesSearch(value, term); // Рекурсивно търсене за вложени обекти
      }
      return false;
    });
  };

  useEffect(() => {
    applyFiltersAndSearch();
  }, [searchTerm, filters, campaigns, filterIsActive, filterIsValid]);

  const applyFiltersAndSearch = () => {
    const filtered = campaigns.filter((campaign) => {
      const matchesSearchTerm = matchesSearch(campaign, searchTerm);

      const matchesFilters = Object.entries(filters).every(([key, value]) => {
        if (!value || value.length === 0) return true;
        if (key === 'category') {
          // Сравняваме ID на категорията
          return value.includes(campaign.category?.name);
        }
        if (Array.isArray(campaign[key])) {
          return campaign[key].some((item) => value.includes(item));
        }
        return value.includes(campaign[key]);
      });

      const matchesIsActive = !filterIsActive || campaign.isActive;
      const matchesIsValid =
        !filterIsValid ||
        (new Date(campaign.startDate) <= new Date() &&
          new Date(campaign.endDate) >= new Date());

      return matchesSearchTerm && matchesFilters && matchesIsActive && matchesIsValid;
    });

    setFilteredCampaigns(filtered);
  };


  useEffect(() => {
    applyFiltersAndSearch();
  }, [searchTerm, filters, campaigns]);



  return (

    <div>
      <Typography variant="h4" gutterBottom>Campaigns</Typography>
      <Button variant="contained" color="primary" onClick={handleAddNew}>Add New Campaign</Button>
      <CampaignFilters
        onFilterChange={handleFilterChange}
        options={{
          tradeSegment: tradeSegmentOptions,
          posTypeNew: posTypeNewOptions,
          kaName: kaNameOptions,
          manned: mannedOptions,
          placementNew: placementNewOptions,
          category: categoryOptions,
          tire: tireOptions,
          screenType: screenTypeOptions
        }}
        multiSelect={true}
      />
      <TextField
        size="small"
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: '20px', marginTop: '10px', width: '100%' }}
      />
      <label>
        <input
          type="checkbox"
          checked={filterIsActive}
          onChange={(e) => setFilterIsActive(e.target.checked)}
        />
        Show Active Only
      </label>
      <label style={{ marginLeft: '20px' }}>
        <input
          type="checkbox"
          checked={filterIsValid}
          onChange={(e) => setFilterIsValid(e.target.checked)}
        />
        Show Valid Only
      </label>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Key Visual</TableCell>
            <TableCell onClick={() => handleSort('name')}>
              Name {sortColumn === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSort('category')}>
              Category {sortColumn === 'category' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSort('startDate')}>
              Start Date {sortColumn === 'startDate' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSort('endDate')}>
              End Date {sortColumn === 'endDate' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSort('isActive')}>
              Status {sortColumn === 'isActive' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell>
              Placement
            </TableCell>
            <TableCell>
              Devices
            </TableCell>
            <TableCell>Segmentations</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredCampaigns.map((campaign) => (
            <TableRow key={campaign._id}>

              <TableCell>
                {campaign.keyVisual ? (
                  campaign.keyVisual.fileType.startsWith('video') ? (
                    <video
                      src={getPublicPath(campaign.keyVisual.localPath || campaign.keyVisual.url || '')}
                      controls
                      style={{ width: '150px', height: 'auto', cursor: 'pointer' }}
                      onClick={() => window.open(getPublicPath(campaign.keyVisual.localPath || campaign.keyVisual.url || ''), '_blank')}
                    />
                  ) : (
                    <img
                      src={getPublicPath(campaign.keyVisual.localPath)}
                      alt="Key Visual"
                      style={{ width: '150px', height: 'auto', cursor: 'pointer' }}
                      onClick={() => window.open(getPublicPath(campaign.keyVisual.localPath), '_blank')}
                    />
                  )
                ) : (
                  'No Key Visual'
                )}
              </TableCell>

              <TableCell style={{ whiteSpace: "break-spaces"}}>{campaign.name}</TableCell>
              <TableCell ><Tooltip title={campaign.category?.name || 'N/A'}>{campaign.category?.name || 'N/A'}</Tooltip> </TableCell>
              <TableCell title={campaign.startDate}>
                <Tooltip title={campaign.startDate}>
                {campaign.startDate
                  ? new Date(campaign.startDate).toLocaleDateString()
                  : 'N/A'}
                </Tooltip>
              </TableCell>
              <TableCell >
                <Tooltip title={campaign.endDate}>
                  {campaign.endDate
                  ? new Date(campaign.endDate).toLocaleDateString()
                  : 'N/A'}
                  </Tooltip>
              </TableCell>
              <TableCell>{campaign.isActive ? "Active" : "Inactive"}</TableCell>
              <TableCell>{campaign.placementNew}</TableCell>
              <TableCell>Devices: {deviceCounts[campaign._id] || 'Loading...'}{' '}</TableCell>

              <TableCell>
                <Tooltip title={dynamicSummaries[campaign._id] || 'No dynamic fields'}>
                  <span>{dynamicSummaries[campaign._id] || 'No dynamic fields'}</span>
                </Tooltip>
              </TableCell>


              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEdit(campaign)}>Edit</Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(campaign._id)}
                  style={{ marginLeft: '10px' }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CampaignEditor
        open={isEditorOpen}
        onClose={() => setEditorOpen(false)}
        initialData={selectedCampaign}
        onSave={handleSave}
      />
    </div>
  );
};

export default Campaigns;