import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <nav>
        <ul>
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/upload-content"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Upload Content
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/campaigns"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Campaign
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/devices"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Devices
            </NavLink>
          </li>
          
          <li>
            <NavLink
              to="/contacts"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Contacts
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/import"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Import File
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;