import React, { useEffect, useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import '../components/DevicesList.css';
import axios from 'axios';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Button,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Devices() {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get('/api/devices');
        setDevices(response.data.devices || []);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, []);

  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
  };

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      // Render array as a list
      return (
        <div>
          <ul>
            {value.map((item, index) => (
              <li key={index}>{typeof item === 'object' ? renderValue(item) : item}</li>
            ))}
          </ul>
        </div>
      );
    } else if (typeof value === 'object' && value !== null) {
      // Render object
      return (
        <div style={{ position: 'relative', marginBottom: '20px' }}>
          <pre style={jsonPreStyle}>{JSON.stringify(value, null, 2)}</pre>
          <button
            style={copyButtonStyle}
            onClick={() => navigator.clipboard.writeText(JSON.stringify(value, null, 2))}
          >
            Copy JSON
          </button>
        </div>
      );
    } else if (value === null || value === undefined) {
      // Handle null or undefined
      return 'N/A';
    } else {
      // Render plain text or number
      return value.toString();
    }
  };


  // Стилове за JSON и копиране
  const jsonPreStyle = {
    backgroundColor: '#f4f4f4',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '14px',
    lineHeight: '1.5',
    overflowX: 'auto',
    maxHeight: '300px',
  };

  const copyButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    padding: '5px 10px',
    fontSize: '12px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  return (

<Grid container spacing={3} >
      {/* Таблицата с устройства */}
      <Grid
        item
        xs={12}
        sm={selectedDevice ? 6 : 12} // Заема цялата ширина, ако няма избрано устройство
        md={selectedDevice ? 8 : 12} // Става по-голямо, ако няма избрано устройство
      >
        <div className="device-list-container"> 
        <Card style={{ padding: '20px' }}>
          <Typography variant="h6">Devices</Typography>
          <Table>
          <TableHead>
      <TableRow>
        <TableCell><strong>POS Name</strong></TableCell>
        <TableCell><strong>City</strong></TableCell>
        <TableCell><strong>Serial Number</strong></TableCell>
        <TableCell><strong>Last Updated</strong></TableCell>
        <TableCell><strong>Status</strong></TableCell>
        <TableCell><strong>Location Name</strong></TableCell>
        <TableCell><strong>Orientation</strong></TableCell>
        <TableCell><strong>POS Type</strong></TableCell>
      </TableRow>
    </TableHead>
            <TableBody>
              {devices.map((device) => (
                <TableRow
                  key={device._id}
                  onClick={() => handleDeviceClick(device)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{device.location?.posName || 'N/A'}</TableCell>
                  <TableCell>{device.location?.city || 'N/A'}</TableCell>
                  <TableCell>{device.sn}</TableCell>

                  <TableCell>
                    {new Date(device.updatedAt).toLocaleString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })}
                  </TableCell>
                  <TableCell>{device.registered ? 'Registered' : 'Not Registered'}</TableCell>
                  <TableCell>{device.location?.name || 'No Location'}</TableCell>
                  <TableCell>{device.location?.orientation || 'No Location'}</TableCell>
                  <TableCell>{device.location?.posType || 'No Location'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
        </div>
      </Grid>

      {/* Детайли за избраното устройство */}
      <Grid item xs={12} sm={6} md={4}>
      <div className="device-details-container">

        {selectedDevice && (
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">Device Details</Typography>
            <Button
              style={{
                position: 'fixed',
                top: '90px',
                right: '20px',
                zIndex: 1000,
              }}
              variant="outlined"
              color="secondary"
              onClick={() => setSelectedDevice(null)} // Нулиране на избраното устройство
            >
              Close
            </Button>
            {Object.entries(selectedDevice).map(([key, value]) => {
              if (
                key === 'vxtConfiguration' ||
                key === 'mqtt' ||
                key === 'location' ||
                key === 'activeMac' ||
                key === 'externalData' ||
                key === 'externalPlaylist'
              ) {
                // Render as Accordion
                return (
                  <Accordion key={key}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{renderValue(value)}</AccordionDetails>
                  </Accordion>
                );
              }
              // Render all other keys as text
              return (
                <Typography key={key} variant="body1" style={{ marginBottom: '10px' }}>
                  <strong>{key}:</strong> {renderValue(value)}
                </Typography>
              );
            })}
          </Card>
        )}
            </div>

      </Grid>

    </Grid>
    
  );
}

export default Devices;