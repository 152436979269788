// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: #f8f9fa;
    padding: 10px 0;
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #ddd;
  }
  
  footer a {
    color: #007bff;
    text-decoration: none;
  }
  
  footer a:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,0BAA0B;EAC5B;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":["footer {\n    background-color: #f8f9fa;\n    padding: 10px 0;\n    text-align: center;\n    margin-top: 20px;\n    border-top: 1px solid #ddd;\n  }\n  \n  footer a {\n    color: #007bff;\n    text-decoration: none;\n  }\n  \n  footer a:hover {\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
