import { io } from 'socket.io-client';

const socket = io('wss://on-labs.eu', {
    path: '/ws',
    transports: ['websocket'],
    secure: true,
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 2000,
    rejectUnauthorized: false
});

socket.on("connect", () => {
    console.log("✅ WebSocket Connected!");
});

socket.on("connect_error", (err) => {
    console.error("❌ WebSocket Connection Error:", err);
});

export default socket;
