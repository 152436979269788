// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.devices-list {
    margin-left: 200px; /* Ширината на менюто */
    padding: 20px;
    margin-top: 70px; /* Отчита височината на хедъра */
  }
  .content-container {
    margin-top: 50px; /* Съответства на височината на хедъра */
    padding: 20px;
    position: relative; /* За правилно позициониране на вътрешните елементи */
    z-index: 1; /* За избягване на конфликти със слоя на бутона */
  }
  .device-list-container {
    height: 80vh; /* Височина, за да създаде скролиращ контейнер */
    overflow-y: auto; /* Вертикално скролиране */
    border: 1px solid #ddd; /* Граница за визуално отделяне */
    padding: 10px;
  }
  
  .device-details-container {
    height: 80vh; /* Височина, същата като на списъка */
    overflow-y: auto; /* Вертикално скролиране */
    border: 1px solid #ddd;
    padding: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/DevicesList.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,uBAAuB;IAC3C,aAAa;IACb,gBAAgB,EAAE,gCAAgC;EACpD;EACA;IACE,gBAAgB,EAAE,wCAAwC;IAC1D,aAAa;IACb,kBAAkB,EAAE,qDAAqD;IACzE,UAAU,EAAE,iDAAiD;EAC/D;EACA;IACE,YAAY,EAAE,gDAAgD;IAC9D,gBAAgB,EAAE,0BAA0B;IAC5C,sBAAsB,EAAE,iCAAiC;IACzD,aAAa;EACf;;EAEA;IACE,YAAY,EAAE,qCAAqC;IACnD,gBAAgB,EAAE,0BAA0B;IAC5C,sBAAsB;IACtB,aAAa;EACf","sourcesContent":[".devices-list {\n    margin-left: 200px; /* Ширината на менюто */\n    padding: 20px;\n    margin-top: 70px; /* Отчита височината на хедъра */\n  }\n  .content-container {\n    margin-top: 50px; /* Съответства на височината на хедъра */\n    padding: 20px;\n    position: relative; /* За правилно позициониране на вътрешните елементи */\n    z-index: 1; /* За избягване на конфликти със слоя на бутона */\n  }\n  .device-list-container {\n    height: 80vh; /* Височина, за да създаде скролиращ контейнер */\n    overflow-y: auto; /* Вертикално скролиране */\n    border: 1px solid #ddd; /* Граница за визуално отделяне */\n    padding: 10px;\n  }\n  \n  .device-details-container {\n    height: 80vh; /* Височина, същата като на списъка */\n    overflow-y: auto; /* Вертикално скролиране */\n    border: 1px solid #ddd;\n    padding: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
