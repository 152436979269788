import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <p>&copy; {new Date().getFullYear()} On-Labs. All Rights Reserved.</p>
        <p>
          Contact us: <a href="mailto:info@on-labs.eu">support@omnichannel.bg</a> | Phone: 0700 700 11
        </p>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#f8f9fa',
    padding: '10px 0',
    textAlign: 'center',
    marginTop: '20px',
    borderTop: '1px solid #ddd',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 15px',
  },
};

export default Footer;