import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Select } from '@mui/material';
import { listCampaigns, assignCampaignToDeviceAPI, unassignCampaignFromDeviceAPI } from '../services/api';

const SelectedDevicesActions = ({ selectedDevices, setSelectedDevices }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState('assign');

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const data = await listCampaigns();
        // Получаваме текущата дата
        const now = new Date();
        console.log('Fetched campaigns:', data, 'now: ',now); // Лог за дебъгване
       
        if (Array.isArray(data) && data.length > 0) {
          //const filteredCampaigns = data.filter(campaign => campaign.manualActivation === true);
          const filteredCampaigns = data.filter(campaign => {
            const startDate = new Date(campaign.startDate);
            const endDate = new Date(campaign.endDate);
  
            // Проверяваме дали текущата дата е между startDate и endDate
            const isInPeriod = startDate <= now && endDate >= now;
  
            return campaign.manualActivation === true && isInPeriod;
          });
          setCampaigns(filteredCampaigns);
        } else {
          console.warn('No campaigns found');
          setCampaigns([]);
        }
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        setCampaigns([]);
      }
    };
    fetchCampaigns();
  }, []);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (type) => {
    setActionType(type);
    setOpenDialog(true);
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmAction = async () => {
    if (!selectedCampaign) return;
    try {
      if (actionType === 'assign') {
        await Promise.all(selectedDevices.map(deviceId => assignCampaignToDeviceAPI(deviceId, selectedCampaign)));
        console.log('Campaign assigned successfully');
      } else {
        await Promise.all(selectedDevices.map(deviceId => unassignCampaignFromDeviceAPI(deviceId, selectedCampaign)));
        console.log('Campaign unassigned successfully');
      }
    } catch (error) {
      console.error(`Error ${actionType === 'assign' ? 'assigning' : 'unassigning'} campaign:`, error);
    }
    handleCloseDialog();
  };

  const handleClearSelection = () => {
    setSelectedDevices([]);
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <Typography variant="subtitle1">
        Selected Devices: {selectedDevices.length}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenMenu}
        disabled={selectedDevices.length === 0}
      >
        Actions
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClearSelection}
        style={{ marginLeft: '10px' }}
        disabled={selectedDevices.length === 0}
      >
        Clear Selection
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem onClick={() => handleOpenDialog('assign')}>Add to Campaign</MenuItem>
        <MenuItem onClick={() => handleOpenDialog('unassign')}>Remove from Campaign</MenuItem>
      </Menu>

      {/* Диалог за избор на кампания */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{actionType === 'assign' ? 'Select a Campaign to Assign' : 'Select a Campaign to Remove'}</DialogTitle>
        <DialogContent>
          {campaigns.length > 0 ? (
            <Select
              value={selectedCampaign}
              onChange={(e) => setSelectedCampaign(e.target.value)}
              fullWidth
            >
              {campaigns.map(campaign => (
                <MenuItem key={campaign._id} value={campaign._id}>{campaign.name}</MenuItem>
              ))}
            </Select>
          ) : (
            <Typography color="error">No campaigns available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
          <Button onClick={handleConfirmAction} color="primary" disabled={!selectedCampaign}>
            {actionType === 'assign' ? 'Assign' : 'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectedDevicesActions;
