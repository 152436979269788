// campaignsPreview.js
import React, { useEffect, useState } from 'react';
import VideoPreviewPlaylist from '../components/VideoPreviewPlaylist';
import { Grid, Typography, Box, Link } from '@mui/material';
import { listCampaigns } from '../services/api';
import CampaignEditor from '../components/CampaignEditor';
import CampaignFilters from '../components/CampaignFilters';

const CampaignPreviews = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [tradeSegmentOptions, setTradeSegmentOptions] = useState([]);
    const [posTypeNewOptions, setPosTypeNewOptions] = useState([]);
    const [kaNameOptions, setKaNameOptions] = useState([]);
    const [mannedOptions, setMannedOptions] = useState([]);
    const [placementNewOptions, setPlacementNewOptions] = useState([]);
    const [tireOptions, setTireOptions] = useState([]);
    const [screenTypeOptions, setScreenTypeOptions] = useState([]);
    const [editingCampaign, setEditingCampaign] = useState(null);
    const [isActiveOptions, setIsActiveOptions] = useState([]);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await listCampaigns();
                const currentDate = new Date();

                // Filter active campaigns within valid date range
                const validCampaigns = response.filter((campaign) => {
                    const validFrom = new Date(campaign.startDate);
                    const validTo = new Date(campaign.endDate);

                    return (
                        //campaign.isActive &&
                        validFrom <= currentDate &&
                        validTo >= currentDate
                    );
                });

                setCampaigns(validCampaigns);
                setFilteredCampaigns(validCampaigns);

                // Unique filter options
                const category = new Set();
                const tradeSegments = new Set();
                const posTypeNew = new Set();
                const kaName = new Set();
                const manned = new Set();
                const placementNew = new Set();
                const tire = new Set();
                const screenType = new Set();
                const isActive = new Set();
                const isActiveOptions = new Set(); // Създайте Set за събиране на уникални стойности


                validCampaigns.forEach((campaign) => {
                    (campaign.tradeSegment || []).forEach((segment) => tradeSegments.add(segment));
                    (campaign.posTypeNew || []).forEach((posType) => posTypeNew.add(posType));
                    (campaign.kaName || []).forEach((ka) => kaName.add(ka));
                    (campaign.manned || []).forEach((man) => manned.add(man));
                    (campaign.placementNew || []).forEach((placement) => placementNew.add(placement));
                    if (campaign.category && campaign.category.name) {
                        category.add(campaign.category.name); // Добавяме името на категорията
                    }
                    (campaign.tire || []).forEach((tir) => tire.add(tir));
                    (campaign.screenType || []).forEach((screen) => screenType.add(screen));
                    isActiveOptions.add(campaign.isActive ? "Yes" : "No");
                });

                setTradeSegmentOptions(Array.from(tradeSegments));
                setPosTypeNewOptions(Array.from(posTypeNew));
                setKaNameOptions(Array.from(kaName));
                setMannedOptions(Array.from(manned));
                setPlacementNewOptions(Array.from(placementNew));
                setCategoryOptions(Array.from(category));
                setTireOptions(Array.from(tire));
                setScreenTypeOptions(Array.from(screenType));
                setIsActiveOptions(Array.from(isActiveOptions));
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            }
        };

        fetchCampaigns();
    }, []);

    const handleFilterChange = (filters) => {
        let updatedCampaigns = campaigns;

        Object.entries(filters).forEach(([key, value]) => {
            if (value.length) {
                updatedCampaigns = updatedCampaigns.filter((campaign) => {
                    if (key.toLowerCase() === 'category') {
                        return value.includes(campaign.category?.name); // Филтриране по име на категорията
                    }
                    if (Array.isArray(campaign[key])) {
                        // Проверяваме дали има съвпадение с елементи в масива
                        return campaign[key].some((item) => value.includes(item));
                    }
                    if (key === 'isActive') {
                        // Преобразуване на булева стойност в "Yes" или "No"
                        const isActiveValue = campaign.isActive ? "Yes" : "No";
                        return value.includes(isActiveValue);
                    }
                    return campaign[key]?.some((item) => value.includes(item));
                });
            }
        });

        setFilteredCampaigns(updatedCampaigns);
    };

    const getLandscapeVideos = (campaign) => {
        if (!campaign.files || !Array.isArray(campaign.files)) {
            return [];
        }

        return campaign.files.filter((file) => {
            if (!file || typeof file.fileType !== 'string') {
                return false;
            }

            return file.fileType.startsWith('video') && file.orientation === 'Landscape';
        });
    };

    const handleEditCampaign = (campaign) => {
        setEditingCampaign(campaign);
    };

    const handleSaveCampaign = (updatedCampaign) => {
        setCampaigns((prev) => prev.map((c) => (c._id === updatedCampaign._id ? updatedCampaign : c)));
        setFilteredCampaigns((prev) => prev.map((c) => (c._id === updatedCampaign._id ? updatedCampaign : c)));
        setEditingCampaign(null);
    };

    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                VALID ONLY Filtered Campaign Previews
            </Typography>

            <CampaignFilters
                options={{
                    tradeSegment: tradeSegmentOptions,
                    posTypeNew: posTypeNewOptions,
                    kaName: kaNameOptions,
                    placementNew: placementNewOptions,
                    manned: mannedOptions,
                    category: categoryOptions,
                    tire: tireOptions,
                    screenType: screenTypeOptions,
                    isActive: isActiveOptions, // Използваме събраните опции

                }}
                onFilterChange={handleFilterChange}
                multiSelect={true}
            />

            <Grid container spacing={2}>
                {filteredCampaigns.map((campaign, index) => {
                    const landscapeVideos = getLandscapeVideos(campaign);
                    return (
                        <Grid item xs={12} sm={6} md={4} key={`${campaign._id}-${index}`}>
                            <Typography
                                variant="subtitle1"
                                component={Link}
                                onClick={() => handleEditCampaign(campaign)}
                                style={{ cursor: 'pointer' }}
                            >
                                {campaign.name}
                            </Typography>

                            <Box style={{ marginBottom: '10px' }}>
                                <Typography variant="body2">
                                    <strong>Category:</strong> {campaign.category?.name || 'N/A'}
                                </Typography>

                                <Typography variant="body2">
                                    <strong>Trade Segment:</strong> {campaign.tradeSegment?.join(', ') || 'N/A'}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>POS Type New:</strong> {campaign.posTypeNew?.join(', ') || 'N/A'}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>KA Name:</strong> {campaign.kaName?.join(', ') || 'N/A'}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>Manned/Unmanned:</strong> {campaign.manned?.join(', ') || 'N/A'}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>Placement New:</strong> {campaign.placementNew?.join(', ') || 'N/A'}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>Tier:</strong> {campaign.tire?.join(', ') || 'N/A'}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>Screen Type:</strong> {campaign.screenType?.join(', ') || 'N/A'}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>Valid from:</strong> {campaign.startDate ? new Date(campaign.startDate).toLocaleDateString() : 'N/A'}
                                </Typography>

                                <Typography variant="body2">
                                    <strong>Valid To:</strong> {campaign.endDate ? new Date(campaign.endDate).toLocaleDateString() : 'N/A'}
                                </Typography>
                                <Typography variant="body2">
                                <strong>Is Active:</strong> {campaign.isActive ? 'Yes' : 'No'}   
                                </Typography>
                            </Box>

                            <VideoPreviewPlaylist playlist={landscapeVideos} />
                        </Grid>
                    );
                })}
            </Grid>

            {editingCampaign && (
                <CampaignEditor
                    open={!!editingCampaign}
                    onClose={() => setEditingCampaign(null)}
                    initialData={editingCampaign}
                    onSave={handleSaveCampaign}
                />
            )}
        </div>
    );
};

export default CampaignPreviews;
