import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Select, MenuItem } from '@mui/material';

function ImportPage() {
  const [file, setFile] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState('');
  const [headers, setHeaders] = useState([]);
  const [mapping, setMapping] = useState({});
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setSheets([]);
    setSelectedSheet('');
    setHeaders([]);
    setMapping({});
  };

  const handlePreviewSheets = async () => {
    if (!file) {
      setMessage('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/api/import/preview', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSheets(response.data.sheetNames || []);
    } catch (error) {
      console.error('Error fetching sheets:', error);
      setMessage('Failed to fetch sheets');
    }
  };

  const handlePreviewHeaders = async () => {
    if (!selectedSheet) {
      setMessage('Please select a sheet');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('sheetName', selectedSheet);

    try {
      const response = await axios.post('/api/import/preview-sheet', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setHeaders(response.data.headers || []);
    } catch (error) {
      console.error('Error fetching headers:', error);
      setMessage('Failed to fetch headers');
    }
  };

  const handleMappingChange = (excelField, dbField) => {
    setMapping((prev) => ({ ...prev, [excelField]: dbField }));
  };

  const handleImport = async () => {
    if (!file || Object.keys(mapping).length === 0) {
      setMessage('Please complete the mapping and select a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('sheetName', selectedSheet);
    formData.append('mapping', JSON.stringify(mapping));

    try {
      const response = await axios.post('/api/import/map-and-import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data.message || 'File imported successfully');
    } catch (error) {
      console.error('Error importing file:', error);
      setMessage('Failed to import file');
    }
  };

  return (
    <div>
      <Typography variant="h4">Import Contacts</Typography>
      <div style={{ marginTop: '20px' }}>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          style={{ marginBottom: '10px' }}
        />
        <br />
        <Button variant="contained" color="primary" onClick={handlePreviewSheets}>
          Preview Sheets
        </Button>
      </div>

      {sheets.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Select Sheet</Typography>
          <Select
            value={selectedSheet}
            onChange={(e) => setSelectedSheet(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Select sheet</MenuItem>
            {sheets.map((sheet) => (
              <MenuItem key={sheet} value={sheet}>
                {sheet}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePreviewHeaders}
            style={{ marginTop: '20px' }}
          >
            Preview Headers
          </Button>
        </div>
      )}

      {headers.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Map Fields</Typography>
          {headers.map((header) => (
            <div key={header} style={{ marginBottom: '10px' }}>
              <Typography variant="body1">{header}</Typography>
              <Select
                value={mapping[header] || ''}
                onChange={(e) => handleMappingChange(header, e.target.value)}
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Select field</MenuItem>
                <MenuItem value="nameLocal">Name</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
              </Select>
            </div>
          ))}
        </div>
      )}

      {headers.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleImport}
          style={{ marginTop: '20px' }}
        >
          Import File
        </Button>
      )}

      {message && (
        <Typography style={{ marginTop: '20px', color: 'green' }}>
          {message}
        </Typography>
      )}
    </div>
  );
}

export default ImportPage;