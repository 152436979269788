import React, { useState, useEffect } from 'react';
import { addContact } from '../services/api';
import CustomSelectWithAdd from './AddableSelect';
import AddableSelect from './AddableSelect';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';

const DeviceFormDialog = ({ open, onClose, onSave, device, uniqueValues, users, setUsers, setUniqueValues }) => {
    const [formValues, setFormValues] = useState({});
    const [searchTerms, setSearchTerms] = useState({});
    const [addingNewScreen, setAddingNewScreen] = useState(false);


    useEffect(() => {
        if (device) {
            setFormValues(device);
        } else {
            setFormValues({});
        }
        console.log('Loaded device:', device);

    }, [device]);

    useEffect(() => {
        console.log('Current formValues:', formValues);
    }, [formValues]);


    const handleAddContact = async (name) => {
        try {
            // Генериране на фиктивен имейл за контакта
            const generatedEmail = `${name.toLowerCase().replace(/\s+/g, '.')}@generated.com`;

            // Създаване на нов контакт
            const response = await addContact({ nameLocal: name, email: generatedEmail });
            const newContact = response.contact;

            // Добавяне на новия контакт в списъка с потребители
            setUsers((prev) => [...prev, newContact]);

            alert(`Contact "${name}" added successfully.`);
        } catch (err) {
            console.error('Error adding contact:', err);
            alert('Failed to add contact.');
        }
    };

    const handleChange = (field, value) => {
        console.log(`Changing ${field} to`, value); // Лог за дебъг
        setFormValues((prev) => ({ ...prev, [field]: value }));
        if (field === 'taActual' && !users.some((user) => user.nameLocal === value)) {
            handleAddContact(value); // Създаване на контакт, ако не съществува
        }
    };

    const handleSearchChange = (field, value) => {
        setSearchTerms((prev) => ({ ...prev, [field]: value }));
    };

    const filteredOptions = (field, options) => {
        const currentValue = formValues[field];
        const searchTerm = searchTerms[field] || '';
        const allOptions = currentValue && !options.includes(currentValue) ? [currentValue, ...options] : options;

        if (!searchTerm) return options;
        return allOptions.filter((option) =>
            option.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const handleSave = () => {
        const defaultValues = {
            outputResolution: '1080p',
            connectivity: ['USB Dongle'],
            splitter: 'No',
            seasonality: 'All Year',
            tvModel: 'Unknown Model',
            playerModel: 'RBPI4',
            numberOfScreens: 1,
            hidden: 0
            //isActive: 1,
        };

        const finalValues = { ...defaultValues, ...formValues };
        console.log('Final values:', finalValues);
        onSave(finalValues);
    };

    const handleAddCustomOption = (field, value) => {
        if (value && !uniqueValues[field]?.includes(value)) {
            setUniqueValues((prev) => ({
                ...prev,
                [field]: [...(prev[field] || []), value], // Добавяне на нова стойност
            }));
            setFormValues((prev) => ({ ...prev, [field]: value })); // Задаване като текуща стойност
        }
    };
    
    const handleIsActiveChange = (event) => {
        const value = parseInt(event.target.value); // Преобразуване в числова стойност
        console.log('Selected value for isActive:', value); // Лог за дебъг
        handleChange('isActive', value);
    };
useEffect(() => {
    if (!uniqueValues.numberOfScreens) {
        setUniqueValues((prev) => ({
            ...prev,
            numberOfScreens: [1], // Добавяне на стойност по подразбиране
        }));
    }
}, [uniqueValues]);

const fields = [
    { key: 'numberOfScreens', label: 'Number of Screens' },
  //  { key: 'hidden', label: 'Hidden (Yes/No)' },
  {key:'placementNew', label: 'Placement'},
  {key: 'displayType', label: 'Display Type- tv/monitor/pro display/LED...'},
    { key: 'displayModel', label: 'TV Model' },
    { key: 'diagonal', label: 'diagonal' },
    { key: 'playerModel', label: 'Player Model' },
  //  { key: 'outputResolution', label: 'Output Resolution' },
    { key: 'connectivity', label: 'Connectivity' },
    //{ key: 'simCardSN', label: 'SIM Card SN' },
    //{ key: 'splitter', label: 'Splitter (Yes/No)' },
    { key: 'seasonality', label: 'Seasonality' },
   
   
    // Добавете още полета тук
];
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{device ? 'Edit Device' : 'Add New Device'}</DialogTitle>
            <DialogContent>
            {formValues.serialNumber && (
    <TextField
        label="Serial Number"
        value={formValues.serialNumber}
        fullWidth
        style={{ marginBottom: '10px', fontWeight: 'bold' }}
        InputProps={{
            readOnly: true, // Прави полето само за четене
        }}
    />
)}
                {Object.entries({
                    name: 'Reev Code *',
                    posName: 'POS Name *',
                    region: 'Region',
                    city: 'City',
                    address: 'Address',
                    talActual: 'TAL',
                    taActual: 'TA',
                    tradeSegment: 'Trade Segment',
                    kaName: 'KA Name',
                    posTypeNew: 'POS Type',
                   
                    manned: 'Manned / Unmanned',
                    tire: 'Tier',
                   // placementNew: 'Placement',
                    screenType: 'Screen Type',
                    orientation: 'Orientation',
                    macAddress: 'MAC Address',
              //      serialNumber: 'Serial Number',
                  //  diagonal: 'Diagonal',
                 //   displayType: 'Display Type',
                  //  displayModel: 'Display Model',
                    outputResolution: 'Output Resolution',
                  //  connectivity: 'Connectivity',
                    simCardSN: 'SIM Card SN',
                    splitter: 'Splitter (Yes/No)',
                    
                  //  playerModel: 'Player Model',
                  //  seasonality: 'Seasonality',
                    // isActive: 'Is Active',
                }).map(([key, label]) => (
                    ['posTypeNew', 'city', 'region', 'tradeSegment', 'orientation', 'screenType', 'kaName', 'manned', 'talActual', 'taActual', 'tire','splitter'].includes(key) ? (
                        <FormControl key={key} fullWidth style={{ marginBottom: '10px' }}>
                            <InputLabel>{label}</InputLabel>
                            <Select
                                value={formValues[key] || ''}
                                onChange={(e) => {
                                    handleChange(key, e.target.value);
                                    handleAddCustomOption(key, e.target.value);
                                }}
                                fullWidth
                                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                                renderValue={(selected) => (selected ? selected : 'Select an option')}
                            >
                                <MenuItem style={{ pointerEvents: 'none', opacity: 1 }}>
                                    <TextField
                                        placeholder="Type to add..."
                                        fullWidth
                                        value={searchTerms[key] || ''}
                                        onChange={(e) => handleSearchChange(key, e.target.value)}
                                        onMouseDown={(e) => e.stopPropagation()}
                                    />
                                </MenuItem>

                                {key === 'talActual'
                                    ? filteredOptions(
                                        key,
                                        formValues[key] && !users.map((user) => user.nameLocal).includes(formValues[key])
                                            ? [formValues[key], ...users.map((user) => user.nameLocal)]
                                            : users.map((user) => user.nameLocal)
                                    ).map((nameLocal) => (
                                        <MenuItem key={nameLocal} value={nameLocal}>
                                            {nameLocal}
                                        </MenuItem>
                                    ))
                                    : filteredOptions(
                                        key,
                                        formValues[key] && !uniqueValues[key]?.includes(formValues[key])
                                            ? [formValues[key], ...(uniqueValues[key] || [])]
                                            : uniqueValues[key] || []
                                    ).map((option) => (
                                        <MenuItem key={`option-${option}`} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>

                    ) : (
                        <TextField
                            key={key}
                            label={label}
                            value={formValues[key] || ''}
                            onChange={(e) => handleChange(key, e.target.value)}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        />
                    )
                ))}
{fields.map(({ key, label }) => (
    <AddableSelect
        key={key}
        label={label}
        value={formValues[key]}
        options={uniqueValues[key] || []}
        onChange={(newValue) => handleChange(key, newValue)}
        onAddNew={(newValue) => handleAddCustomOption(key, newValue)}
    />
))}


                <FormControl fullWidth style={{ marginBottom: '10px' }}>
                    <InputLabel>Is Active*</InputLabel>
                    <Select
                        value={formValues.isActive !== undefined ? formValues.isActive : 1} // По подразбиране само ако isActive е undefined

                        onChange={handleIsActiveChange} // Извикване на помощната функция
                    >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeviceFormDialog;
