// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaign-device-logs {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .campaign-device-logs h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .assignments-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .assignments-table thead {
    background-color: #f4f4f4;
  }
  
  .assignments-table th,
  .assignments-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .assignments-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .assignments-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .assignments-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .assignments-table td {
    color: #555;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CampaignDeviceLogs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;EACb","sourcesContent":[".campaign-device-logs {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .campaign-device-logs h2 {\n    text-align: center;\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .assignments-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 0 auto;\n  }\n  \n  .assignments-table thead {\n    background-color: #f4f4f4;\n  }\n  \n  .assignments-table th,\n  .assignments-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  .assignments-table th {\n    background-color: #007bff;\n    color: white;\n    font-weight: bold;\n  }\n  \n  .assignments-table tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .assignments-table tr:hover {\n    background-color: #f1f1f1;\n  }\n  \n  .assignments-table td {\n    color: #555;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
