import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import rehypeSlug from "rehype-slug";
import rehypeAutolinkHeadings from "rehype-autolink-headings";
import remarkToc from "remark-toc";
import rehypeRaw from "rehype-raw"; 

export default function Instructions() {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    fetch("/content/UserManual.mdx")
      .then((res) => res.text())
      .then(setMarkdownContent);
  }, []);

  return (
    <div className="markdown-container">
      <ReactMarkdown
        remarkPlugins={[remarkToc]}
        rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings, rehypeRaw]}
        components={{
          img: ({ src, alt }) => (
            <div className="image-row">
              <img src={src} alt={alt} />
            </div>
          ),
        }}
      >
        {markdownContent}
      </ReactMarkdown>

     
    </div>

    
  );
}
