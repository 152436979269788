import React from 'react';
import { NavLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './Sidebar.css';
import { jwtDecode } from 'jwt-decode';
import { useMenuAccess } from '../context/MenuAccessContext';

const drawerWidth = 200;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Sidebar = ({ open, setOpen }) => {
  const token = localStorage.getItem('token');
  //const userRoles = token ? jwtDecode(token).roles : [];
  const decodedToken = token ? jwtDecode(token) : {};
  //const menuAccess = decodedToken.menuAccess || [];
  const userRoles = decodedToken.roles || [];
  const menuAccess = useMenuAccess();
  console.log('Menu access:', menuAccess);


  const theme = useTheme();


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menus = [
    { name: 'Dashboard', path: '/dashboard' },
    { name: 'Campaigns', path: '/campaigns' },
    { name: 'Contacts', path: '/contacts' },
    { name: 'Devices', path: '/devices' },
    { name: 'PMI Devices', path: '/pmi-devices' },
    { name: 'My Devices', path: '/my-devices' },
    { name: 'Import', path: '/import' },
    { name: 'Upload Content', path: '/upload-content' },
    { name: 'Files', path: '/list-files' },
    { name: 'Categories', path: '/categories' },
    { name: 'Campaigns Preview', path: '/campaign-preview' },
    { name: 'Campaign Assignments', path: '/manual-campaign-assignment' },
    { name: 'Campaign Devices Logs', path: '/campaign-device-logs' },
  ];
  return (


    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <nav >
        <ul>
          {menus
            .filter((menu) => menuAccess.includes(menu.name.toLowerCase()))
            .map((menu) => (
              <li key={menu.name}>
                <NavLink to={menu.path}>{menu.name}</NavLink>
              </li>
            ))}
        </ul>
      </nav>


      <nav className='sidebar'>
        <ul>
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Dashboard
            </NavLink>
          </li>

          {userRoles.includes('admin') && (
            <li>
              <NavLink
                to="/campaign-previews"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Campaign-previews
              </NavLink>
            </li>
          )}

          {userRoles.includes('TestAdmin') && (
            <li>
              <NavLink
                to="/simulation-results" className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Simulation Results
              </NavLink>
            </li>
          )}

          {userRoles.includes('TestAdmin') && (
            <li>
              <NavLink
                to="/simulation-Page"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Simulation Page
              </NavLink>
            </li>
          )}

          {userRoles.includes('admin') && (
            <li>
              <NavLink
                to="/upload-content"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Upload Content
              </NavLink>
            </li>
          )}

          {userRoles.includes('admin') && (
            <li>
              <NavLink
                to="/list-files"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Files
              </NavLink>
            </li>
          )}

          {userRoles.includes('admin') && (
            <li>
              <NavLink
                to="/categories"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Categories
              </NavLink>
            </li>
          )}

          {userRoles.includes('admin') && (
            <li>
              <NavLink
                to="/campaigns"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Campaign
              </NavLink>
            </li>
          )}

          {userRoles.includes('TestAdmin') && (
            <li>
              <NavLink
                to="/manual-campaign-assignment"
                className={({ isActive }) => (isActive ? 'active-link' : '')}>
                Campaign Assignments
              </NavLink>
            </li>
          )}
          {userRoles.includes('admin') && (
            <li>
              <NavLink
                to="/campaign-device-logs"
                className={({ isActive }) => (isActive ? 'active-link' : '')}>
                Campaign devices logs
              </NavLink>
            </li>
          )}

          {/*
            <li>
              <NavLink
                to="/campaignsDialog"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Campaign Dialog
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/playlistsDialog"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Playlist
              </NavLink>
            </li>
            */}
          {/*
            <li>
              <NavLink
                to="/create-playlist"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Create Playlist
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/playlists"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Playlists
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/edit-playlist"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Edit Playlist
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/add-elements"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Add Elements
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/playlists"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Playlists
              </NavLink>
            </li>
            */}
          {userRoles.includes('admin') && (
            <li>
              <NavLink
                to="/devices"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Devices
              </NavLink>
            </li>
          )}

          {/*      {userRoles.includes('viewer') && ( */}
          <li>
            <NavLink
              to="/pmi-devices"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              PMI Devices
            </NavLink>
          </li>
          {/*          )} */}

          {/* Ново меню за My Devices */}
          <li>
            <NavLink
              to="/my-devices"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              My Devices
            </NavLink>
          </li>

          {userRoles.includes('TestAdmin') && (
            <li>
              <NavLink
                to="/contacts"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Contacts
              </NavLink>
            </li>
          )}

          <li>
            <NavLink
              to="/instructions"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              📜 Instructions
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/instructionsNew"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              📖 Инструкции
            </NavLink>
          </li>


          {userRoles.includes('admin') && (
            <li>
              <NavLink
                to="/import"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Import File
              </NavLink>
            </li>
          )}

          {userRoles.includes('admin') && (
            <li>
              <NavLink
                to="/dashboardStatus"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Stats
              </NavLink>
            </li>
          )}

        </ul>
      </nav>
    </Drawer>

  );
};

export default Sidebar;