import axios from 'axios';

// Основен API клиент за on-labs.eu
export const apiClient = axios.create({
  baseURL: 'https://on-labs.eu/api', // Основен URL за on-labs
  timeout: 10000, 
  headers: { 'Content-Type': 'application/json' }
});

// API клиент за статистиката на устройствата (localhost:3004)
export const deviceApiClient = axios.create({
  baseURL: 'https://on-labs.eu/stats', // Локален API за устройства
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
});