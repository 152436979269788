import React, { useState, useEffect } from 'react';
import { getAllAssignmentsAPI, getCampaignsForDeviceAPI, getDevicesForCampaignAPI, getHistoryForDeviceAPI, getActionLogsAPI,getCampaignDetailsAPI,unassignCampaignFromDeviceAPI } from '../services/api';
import './CampaignDeviceLogs.css';

const CampaignDeviceLogs = () => {
  const [assignments, setAssignments] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [campaignsForDevice, setCampaignsForDevice] = useState([]);
  const [devicesForCampaign, setDevicesForCampaign] = useState([]);
  const [deviceHistory, setDeviceHistory] = useState([]);
  const [actionLogs, setActionLogs] = useState([]);
  const [selectedCampaignDetails, setSelectedCampaignDetails] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState([]); // Избрани устройства
  const [showActiveOnly, setShowActiveOnly] = useState(false); // Филтър за активни връзки

  // Извличане на всички записи
  const fetchAllAssignments = async () => {
    try {
      const data = await getAllAssignmentsAPI();
      setAssignments(data);
    } catch (error) {
      console.error('Error fetching all assignments:', error);
    }
  };

    // Форматиране на дати
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
      };
/*
  useEffect(() => {
    if (selectedCampaignId) {
      const campaignAssignment = assignments.find(
        (assignment) => assignment.campaignId?._id === selectedCampaignId
      );
      if (campaignAssignment) {
        setSelectedCampaignDetails(campaignAssignment.campaignId); // Пълни детайли за кампанията
        console.log('Selected campaign details:', campaignAssignment.campaignId); // Лог за дебъгване
      } else {
        setSelectedCampaignDetails(null);
      }
    }
  }, [selectedCampaignId, assignments]);
  */
  useEffect(() => {
    const fetchCampaignDetails = async () => {
      if (selectedCampaignId) {
        try {
          const campaignDetails = await getCampaignDetailsAPI(selectedCampaignId);
          setSelectedCampaignDetails(campaignDetails);
        } catch (error) {
          console.error('Error fetching campaign details:', error);
        }
      }
    };
    fetchCampaignDetails();
  }, [selectedCampaignId]);
  
  // Извличане на кампании за конкретно устройство
  const fetchCampaignsForDevice = async () => {
    try {
      const data = await getCampaignsForDeviceAPI(selectedDeviceId);
      setCampaignsForDevice(data);
    } catch (error) {
      console.error('Error fetching campaigns for device:', error);
    }
  };

  // Извличане на устройства за конкретна кампания
  const fetchDevicesForCampaign = async () => {
    try {
      const data = await getDevicesForCampaignAPI(selectedCampaignId);
      setDevicesForCampaign(data);
      console.log('Devices for campaign:', data); // Лог за дебъгване
    } catch (error) {
      console.error('Error fetching devices for campaign:', error);
    }
  };

  // Извличане на история за устройство
  const fetchDeviceHistory = async () => {
    try {
      const data = await getHistoryForDeviceAPI(selectedDeviceId);
      setDeviceHistory(data.history || []);
    } catch (error) {
      console.error('Error fetching device history:', error);
    }
  };

  // Извличане на логове
  const fetchActionLogs = async () => {
    try {
      const data = await getActionLogsAPI({});
      setActionLogs(data);
    } catch (error) {
      console.error('Error fetching action logs:', error);
    }
  };

  // При стартиране извличаме всички записи и логове
  useEffect(() => {
    fetchAllAssignments();
    fetchActionLogs();
  }, []);

  // При избор на устройство извличаме свързаните кампании и история
  useEffect(() => {
    if (selectedDeviceId) {
      fetchCampaignsForDevice();
      fetchDeviceHistory();
    }
  }, [selectedDeviceId]);
/*
  // При избор на кампания извличаме свързаните устройства
  useEffect(() => {
    if (selectedCampaignId) {
      fetchDevicesForCampaign();
    }
  }, [selectedCampaignId]);
*/
useEffect(() => {
    if (selectedCampaignId) {
      const relatedDevices = assignments
        .filter((assignment) => assignment.campaignId?._id === selectedCampaignId)
        .map((assignment) => {
          //console.log('Device Data:', assignment.deviceId); // Логваме данните за устройството
          return {
            ...assignment.deviceId,
            assignmentId: assignment._id, // За връзката
            isActive: assignment.isActive,
            updatedAt: assignment.updatedAt,
            assignedAt: assignment.assignedAt,
          };
        });
        console.log('Filtered Devices:', relatedDevices,'show Active' ,showActiveOnly); // Логваме списъка с устройства
// При промяна на `showActiveOnly` се обновява списъкът
const filteredDevices = showActiveOnly
? relatedDevices.filter((device) => device.isActive)
: relatedDevices;

setDevicesForCampaign(filteredDevices);
        //setDevicesForCampaign(showActiveOnly ? relatedDevices.filter((device) => device.isActive) : relatedDevices);
    }
  }, [selectedCampaignId, assignments,showActiveOnly]);
  

  const handleDeviceSelection = (deviceId) => {
    setSelectedDevices((prevSelected) =>
      prevSelected.includes(deviceId)
        ? prevSelected.filter((id) => id !== deviceId)
        : [...prevSelected, deviceId]
    );
  };

  const handleUnassignDevices = async () => {
    console.log('Unassigning devices:', selectedDevices, 'from campaign:', selectedCampaignId); 
    try {
      const promises = selectedDevices.map((deviceId) =>
        
        unassignCampaignFromDeviceAPI(deviceId, selectedCampaignId)
      );
      await Promise.all(promises);

      // Обновяване на списъка с устройства
      setDevicesForCampaign((prevDevices) =>
        prevDevices.filter((device) => !selectedDevices.includes(device._id))
      );
      setSelectedDevices([]);
      alert('Selected devices have been unassigned successfully.');
    } catch (error) {
      console.error('Error unassigning devices:', error);
      alert('Failed to unassign selected devices.');
    }
  };

  const handleSelectAll = () => {
    if (selectedDevices.length === devicesForCampaign.length) {
      setSelectedDevices([]); // Деселектиране на всички
    } else {
      setSelectedDevices(devicesForCampaign.map((device) => device._id)); // Селектиране на всички
    }
  };
  useEffect(() => {
    const activeAssignments = assignments.filter(a => a.isActive);
    console.log('Active Assignments:', activeAssignments);
  }, [assignments]);
  
  return (
    <div className="campaign-device-logs">
      <h2>Campaign and Device Assignments</h2>

      <div>
        <h3>Select Device</h3>
        <select onChange={(e) => setSelectedDeviceId(e.target.value)}>
          <option value="">Select Device</option>
          {[...new Map(assignments.map(a => [a.deviceId?._id, a.deviceId])).values()].map((device) => (
            <option key={device._id} value={device._id}>{device.name}</option>
          ))}
        </select>

        <table className="assignments-table">
          <thead>
            <tr>
              <th>Campaign Name</th>
                <th>Category</th>
              <th>Start Date</th>
                <th>End Date</th>
                <th>Trade Segment</th>
                <th>KA Name</th>
                <th>POS Type</th>
                <th>Placement</th>
                <th>Manned</th>
            </tr>
          </thead>
          <tbody>
            {campaignsForDevice.map((campaign) => (
              <tr key={campaign._id}>
                <td>{campaign.name}</td>
                <td>{campaign.category}</td>
                <td>{campaign.startDate}</td>
                <td>{campaign.endDate}</td>
                <td>{campaign.tradeSegment}</td>
                <td>{campaign.kaName}</td>
                <td>{campaign.posTypeNew}</td>
                <td>{campaign.placementNew}</td>
                <td>{campaign.manned}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h3>Select Campaign</h3>
        <select onChange={(e) => setSelectedCampaignId(e.target.value)}>
          <option value="">Select Campaign</option>
          {[...new Map(assignments.filter(a => a.isActive).map(a => [a.campaignId?._id, a.campaignId])).values()].map((campaign) => (
            <option key={campaign._id} value={campaign._id}>{campaign.name}</option>
          ))}
        </select>
        <div>
          <label>
            <input
              type="checkbox"
              checked={showActiveOnly}
              onChange={(e) => setShowActiveOnly(e.target.checked)}
            />
            Show Active Only
          </label>
        </div>
        <button onClick={handleSelectAll} className="select-all-button">
          {selectedDevices.length === devicesForCampaign.length ? 'Deselect All' : 'Select All'}
        </button>
        <button
          onClick={handleUnassignDevices}
          disabled={selectedDevices.length === 0}
          className="unassign-button"
        >
          Unassign Selected Devices
        </button>

        {selectedCampaignDetails && (
            <table className="assignments-table">
    <thead>
      <tr>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Trade Segment</th>
        <th>kaName</th>
        <th>POS Type</th>
        <th>Manned</th>
        <th>Placement</th>
        <th>Tier</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{formatDate(selectedCampaignDetails.startDate)}</td>
        <td>{formatDate(selectedCampaignDetails.endDate)}</td>
        <td>{selectedCampaignDetails.tradeSegment?.join(', ') || 'N/A'}</td>
        <td>{selectedCampaignDetails.kaName?.join(', ') || 'N/A'}</td>
        <td>{selectedCampaignDetails.posTypeNew?.join(', ') || 'N/A'}</td>
        <td>{selectedCampaignDetails.manned?.join(', ') || 'N/A'}</td>
        <td>{selectedCampaignDetails.placementNew?.join(', ') || 'N/A'}</td>
        <td>{selectedCampaignDetails.tire?.join(', ') || 'N/A'}</td>
      </tr>
    </tbody>
  </table>
        )}
        <table className="assignments-table">
          <thead>
            <tr>
            <th>Select</th>
              <th>Device Name</th>
              <th>Serial Number</th>
              <th>POS Name</th>
              <th>Trade Segment</th>
              <th>KA Name</th>
              <th>POS Type</th>
              <th>Manned</th>
              <th>Placement</th>
              <th>Tier</th>
                <th>Active</th>
                <th>Updated At</th>
                <th>Assigned At</th>
            </tr>
          </thead>
          <tbody>
            {devicesForCampaign.map((device) => (
              <tr key={device._id}>
                <td>
          <input
            type="checkbox"
            checked={selectedDevices.includes(device._id)}
            onChange={() => handleDeviceSelection(device._id)}
          />
        </td>
                <td>{device.name}</td>
                <td>{device.serialNumber}</td>
                <td>{device.posName || 'N/A'}</td>
                <td>{device.tradeSegment || 'N/A'}</td>
                <td>{device.kaName || 'N/A'}</td>
                <td>{device.posTypeNew || 'N/A'}</td>
                <td>{device.manned || 'N/A'}</td>
                <td>{device.placementNew || 'N/A'}</td>
                <td>{device.tire || 'N/A'}</td>
                <td>{device.isActive === true ? 'Yes' : 'No'}</td>
                <td>{device.updatedAt || 'N/A'}</td>
                <td>{device.assignedAt || 'N/A'}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h3>Device History</h3>
        <table className="assignments-table">
          <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Assigned At</th>
            </tr>
          </thead>
          <tbody>
            {deviceHistory.map((entry, index) => (
              <tr key={index}>
                <td>{entry.campaignId.name}</td>
                <td>{new Date(entry.assignedAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h3>Action Logs</h3>
        <table className="assignments-table">
          <thead>
            <tr>
              <th>Action</th>
              <th>User</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {actionLogs.map((log, index) => (
              <tr key={index}>
                <td>{log.action}</td>
                <td>{log.user}</td>
                <td>{log.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CampaignDeviceLogs;
