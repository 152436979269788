import React, { useEffect, useState } from 'react';
import { listCampaigns, getPmiDevices, assignCampaignToDevice, unassignCampaignFromDevice, getCampaignsForDevice } from '../services/api';
import { getDevicesCount, getDeviceCountByCampaign, assignCampaignToDeviceAPI, unassignCampaignFromDeviceAPI, getCampaignsForDeviceAPI } from '../services/api';

const CampaignDeviceAssignment = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [devices, setDevices] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [assignedCampaigns, setAssignedCampaigns] = useState([]);

    useEffect(() => {
        fetchCampaigns();
        fetchDevices();
    }, []);

    const fetchCampaigns = async () => {
        try {
            const data = await listCampaigns();
            setCampaigns(data);
        } catch (error) {
            console.error('Error fetching campaigns:', error);
        }
    };

    const fetchDevices = async () => {
        try {
            const data = await getPmiDevices();
            setDevices(data);
        } catch (error) {
            console.error('Error fetching devices:', error);
        }
    };

    const handleDeviceSelect = async (deviceId) => {
        setSelectedDevice(deviceId);
        try {
            const assigned = await getCampaignsForDeviceAPI(deviceId);
            setAssignedCampaigns(assigned);
        } catch (error) {
            console.error('Error fetching assigned campaigns:', error);
        }
    };

    const handleAssign = async () => {
        if (!selectedCampaign || !selectedDevice) return;
        try {
            await assignCampaignToDeviceAPI(selectedDevice, selectedCampaign);
            setAssignedCampaigns([...assignedCampaigns, { _id: selectedCampaign }]);
        } catch (error) {
            console.error('Error assigning campaign:', error);
        }
    };

    const handleUnassign = async (campaignId) => {
        try {
            await unassignCampaignFromDeviceAPI(selectedDevice, campaignId);
            setAssignedCampaigns(assignedCampaigns.filter(c => c._id !== campaignId));
        } catch (error) {
            console.error('Error unassigning campaign:', error);
        }
    };

    return (
        <div>
            <h2>Assign Campaigns to Devices</h2>
            <label>Choose Device:</label>
            <select onChange={(e) => handleDeviceSelect(e.target.value)}>
                <option value="">Select a Device</option>
                {devices.map(device => (
                    <option key={device._id} value={device._id}>{device.name}</option>
                ))}
            </select>
            
            <h3>Assigned Campaigns:</h3>
            <ul>
                {assignedCampaigns.map(campaign => (
                    <li key={campaign._id}>
                        {campaign.name}
                        <button onClick={() => handleUnassign(campaign._id)}>Unassign</button>
                    </li>
                ))}
            </ul>
            
            <label>Choose Campaign:</label>
            <select onChange={(e) => setSelectedCampaign(e.target.value)}>
                <option value="">Select a Campaign</option>
                {campaigns.map(campaign => (
                    <option key={campaign._id} value={campaign._id}>{campaign.name}</option>
                ))}
            </select>
            
            <button onClick={handleAssign} disabled={!selectedCampaign || !selectedDevice}>
                Assign Campaign
            </button>
        </div>
    );
};

export default CampaignDeviceAssignment;
