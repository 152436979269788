let clientId = localStorage.getItem("clientId");

const reconnectInterval = 5000; // 5 секунди между опитите за свързване

function setupWebSocket() {

const socket = new WebSocket("wss://oc-control.eu:3002");

// Зареждаме `clientId` от Local Storage
let clientId = localStorage.getItem("clientId");

socket.onopen = () => {
    console.log("✅ WebSocket Connected!");

    if (!clientId) {
        console.warn("⚠ clientId липсва, създаваме нов.");
        clientId = `client-${Math.random().toString(36).substr(2, 9)}`;
        localStorage.setItem("clientId", clientId);
    }

    socket.send(JSON.stringify({ type: "reconnect_client", clientId }));
};

socket.onmessage = (event) => {
    try {
        const data = JSON.parse(event.data);

        if (data.clientId) {
            if (!clientId) {
                clientId = data.clientId;
                localStorage.setItem("clientId", clientId);
            }
            console.log(`🆔 Получен клиентски ID: ${clientId}`);
        }

        if (data.data) {
            console.log("📩 MQTT съобщение:", data);
        }
    } catch (error) {
        console.error("❌ Грешка при обработка на WebSocket съобщение:", error);
    }
};


socket.onerror = (error) => {
    console.error("❌ WebSocket Грешка:", error);
};

socket.onclose = () => {
    console.log("❌ Връзката с WebSocket е затворена");
};
return socket;
}
const socket = setupWebSocket();
export { socket, clientId };
