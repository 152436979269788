// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.sidebar {
    position: fixed;
    top: 60px; 
    left: 0;
    width: 180px;
    height: calc(100vh - 70px);
    background-color: #f5f5f5;
    border-right: 1px solid #ddd;
    overflow-y: auto;
  }*/
  .sidebar{
    padding-left: 15px;
  }
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 10px 0;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
  }
  
  .sidebar ul li a.active-link {
    font-weight: bold;
    color: #007bff;
  }`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA;;;;;;;;;IASI;EACF;IACE,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,qBAAqB;IACrB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB","sourcesContent":["/*.sidebar {\n    position: fixed;\n    top: 60px; \n    left: 0;\n    width: 180px;\n    height: calc(100vh - 70px);\n    background-color: #f5f5f5;\n    border-right: 1px solid #ddd;\n    overflow-y: auto;\n  }*/\n  .sidebar{\n    padding-left: 15px;\n  }\n  .sidebar ul {\n    list-style: none;\n    padding: 0;\n  }\n  \n  .sidebar ul li {\n    margin: 10px 0;\n  }\n  \n  .sidebar ul li a {\n    text-decoration: none;\n    color: #333;\n    font-size: 18px;\n  }\n  \n  .sidebar ul li a.active-link {\n    font-weight: bold;\n    color: #007bff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
