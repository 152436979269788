import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Login({ setToken }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // За навигация

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        console.log(email   + " " + password);
      const response = await axios.post('https://on-labs.eu/api/auth/login', { email, password });
      console.log(response.data);
     // alert('Login successful!');
      if (response.data.token) {
        setToken(response.data.token); // Запазва токена
        navigate('/dashboard'); // Пренасочва към Dashboard
      } else {
        alert('Login failed!');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred during login!');
    }
  };

  return (
    <div className="auth-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;