// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Фиксиран хедър */
/*.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px; 
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}*/

/* Страничното меню под хедъра */
.sidebar {
  position: fixed;
  top: 70px; /* Колкото е височината на хедъра */
  left: 0;
  width: 200px;
  height: calc(100% - 70px);
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  padding: 10px;
}

/* Основното съдържание */
.content {
  margin-left: 260px; /* Ширината на Sidebar-а */
  margin-top: 70px; /* Височината на Header-а */
  padding: 20px;
  width: calc(100% - 240px);
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;;;;;;;;;EASE;;AAEF,gCAAgC;AAChC;EACE,eAAe;EACf,SAAS,EAAE,mCAAmC;EAC9C,OAAO;EACP,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,4BAA4B;EAC5B,gBAAgB;EAChB,aAAa;AACf;;AAEA,yBAAyB;AACzB;EACE,kBAAkB,EAAE,0BAA0B;EAC9C,gBAAgB,EAAE,2BAA2B;EAC7C,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":["/* Фиксиран хедър */\n/*.header {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 70px; \n  background-color: #ffffff;\n  z-index: 1000;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n}*/\n\n/* Страничното меню под хедъра */\n.sidebar {\n  position: fixed;\n  top: 70px; /* Колкото е височината на хедъра */\n  left: 0;\n  width: 200px;\n  height: calc(100% - 70px);\n  background-color: #f8f9fa;\n  border-right: 1px solid #ddd;\n  overflow-y: auto;\n  padding: 10px;\n}\n\n/* Основното съдържание */\n.content {\n  margin-left: 260px; /* Ширината на Sidebar-а */\n  margin-top: 70px; /* Височината на Header-а */\n  padding: 20px;\n  width: calc(100% - 240px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
