import React from 'react';
import './Header.css'; // CSS за стилизация

function Header() {
  return (
    <header className="header">
      <img src="/onlabs-logo.png" alt="Logo" className="header-logo" />
    </header>
  );
}

export default Header;