import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { styled, useTheme } from '@mui/material/styles';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Dashboard from './pages/Dashboard';
import Campaigns from './pages/CampaignsNew';
import CampaignsDialog from './pages/CampaignsDialog';
import AddPlaylist from './components/AddPlaylist';
import PlaylistsList from './pages/Playlists';
//import EditPlaylist from './pages/EditPlaylist';
import AddElements from './components/AddElements';
import Devices from './pages/Devices';
import Contacts from './pages/Contacts';
import Login from './pages/Login';
import Register from './pages/Register';
import ProtectedRoute from './components/ProtectedRoute';
import ImportPage from './pages/ImportPage';
//import UploadContent from './pages/UploadContent';
import UploadFile from './components/UploadFile';
import FileList from './components/FileList';
import PlaylistsDialog from './pages/PlaylistDialog';
import CategoryManager from './components/CategoryManager';
import PmiDevicesTable from './pages/PmiDevicesTable';
import UserDevicesTable from './pages/UserDevicesTable';
import CampaignPreviews from './pages/campaignsPreview';
import { useMenuAccess } from './context/MenuAccessContext';
import SimulationResultsTable from './pages/SimulationResultsTable';
import SimulationResultsPage from './pages/SimulationResultsPage';
import CampaignDeviceAssignment from './components/CampaignDeviceAssignment';
import CampaignDeviceLogs, { camapaignDeviceLog } from './components/CampaignDeviceLogs';
import Instructions from './pages/Instructions';
import InstructionsNew from "./components/instructionMarkdown"; // Обновена инструкция
import DashboardStatus from "./components/DashboardStatus"; // Обновен Dashboard

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [open, setOpen] = useState(window.innerWidth > 768);
  const decodedToken = token ? jwtDecode(token) : {};
  const menuAccess = decodedToken.menuAccess || [];

  useEffect(() => {
    if (token) {
      const isValid = checkTokenValidity(token);
      if (!isValid) {
        console.log('Token is invalid or expired. Logging out...');
        localStorage.removeItem('token'); // Изчистване на токена
        setToken(null); // Обновяване на състоянието
      }
    }
  }, [token]);


  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth > 768); // При resize проверява размера
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  /*
    useEffect(() => {
      if (!token) {
        navigate('/login'); // Пренасочва към логин, ако няма токен
      }
    }, [token, navigate]);
  */
  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  const checkTokenValidity = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp >= currentTime;
    } catch (error) {
      console.error('Invalid token:', error.message);
      return false;
    }
  };

  const drawerWidth = 200;
  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      variants: [
        {
          props: ({ open }) => open,
          style: {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          },
        },
      ],
    }),
  );

  return (
    <Router>
      <div className="app">
        {/* Хедър */}

        <Header open={open} setOpen={setOpen} />

        {/* Странично меню и съдържание */}
        <div style={{ display: 'flex' }}>

          <Sidebar open={open} setOpen={setOpen} menuAccess={menuAccess} />
          <Main open={open}>
            <div className="content">
              <Routes>
                <Route path="/login" element={<Login setToken={saveToken} />} />
                <Route path="/register" element={<Register />} />

                <Route
                  path="/campaign-previews"
                  element={
                    <ProtectedRoute token={token}>
                      <CampaignPreviews />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/simulation-results"
                  element={
                    <ProtectedRoute token={token}>
                      <SimulationResultsTable />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/simulation-Page"
                  element={
                    <ProtectedRoute token={token}>
                      <SimulationResultsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/devices"
                  element={
                    <ProtectedRoute token={token}>
                      <Devices />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/pmi-devices"
                  element={
                    <ProtectedRoute token={token}>
                      <PmiDevicesTable />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/my-devices"
                  element={
                    <ProtectedRoute token={token}>
                      <UserDevicesTable />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/pmi-devices/edit/:id"
                  element={
                    <ProtectedRoute token={token}>
                      <PmiDevicesTable />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/my-devices/edit/:id"
                  element={
                    <ProtectedRoute token={token}>
                      <PmiDevicesTable />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute token={token}>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/upload-content"
                  element={
                    <ProtectedRoute token={token}>
                      <UploadFile /> {/* Използване на компонента за качване */}
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/list-files"
                  element={
                    <ProtectedRoute token={token}>
                      <FileList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/categories"
                  element={
                    <ProtectedRoute token={token}>
                      <CategoryManager />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/manual-campaign-assignment"
                  element={
                    <ProtectedRoute token={token}>
                      <CampaignDeviceAssignment />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/campaign-device-logs"
                  element={
                    <ProtectedRoute token={token}>
                      <CampaignDeviceLogs />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/campaigns"
                  element={
                    <ProtectedRoute token={token}>
                      <Campaigns />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/campaignsDialog"
                  element={
                    <ProtectedRoute token={token}>
                      <CampaignsDialog />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/playlistsDialog"
                  element={
                    <ProtectedRoute token={token}>
                      <PlaylistsDialog />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/playlists"
                  element={
                    <ProtectedRoute token={token}>
                      <PlaylistsList />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/add-elements"
                  element={
                    <ProtectedRoute token={token}>
                      <AddElements onAdd={() => { }} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-playlist"
                  element={
                    <ProtectedRoute token={token}>
                      <AddPlaylist />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/contacts"
                  element={
                    <ProtectedRoute token={token}>
                      <Contacts />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/instructions"
                  element={
                    <ProtectedRoute token={token}>
                      <Instructions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/InstructionsNew"
                  element={
                    <ProtectedRoute token={token}>
                      <InstructionsNew />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/import"
                  element={
                    <ProtectedRoute token={token}>
                      <ImportPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboardStatus"
                  element={
                    <ProtectedRoute token={token}>
                      <DashboardStatus />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </div>
          </Main>
        </div>
        {/* Футър */}
        <Footer />
      </div>
    </Router>
  );
}


export default App;

/*
      <Route
                path="/edit-playlist/:playlistId"
                element={
                  <ProtectedRoute token={token}>
                    <EditPlaylist />
                  </ProtectedRoute>
                }
              />
*/