import React, { useEffect, useState } from 'react';
import '../components/DevicesList.css';
import { getDevicePlaylist } from '../services/api';
import axios from 'axios';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Pagination,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoPreviewPlaylist from '../components/VideoPreviewPlaylist';
import DeviceFilters from '../components/DeviceFilters';


function Devices() {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [playlist, setPlaylist] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const [filters, setFilters] = useState({
    deviceName: '',
    isRegistered: '',
   
    hasNoLocationNew: false, // Добавяме липсващото поле
    localDeviceFilters: {
      name: '',
      sn: '',
      registered: '',
      activeMac: '',
  },
  
  });
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;


  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get('/api/devices');
        setDevices(response.data.devices || []);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    const filtered = devices.filter((device) => {
      return (
        (!filters.sn || device.sn?.toLowerCase().includes(filters.sn.toLowerCase())) &&
        (!filters.activeMac || device.activeMac?.some(mac => mac.mac.toLowerCase().includes(filters.activeMac.toLowerCase()))) &&
        (!filters.registered || device.registered === filters.registered) &&
        (!filters.appVersion || device.appVersion?.includes(filters.appVersion)) &&
        (!filters.deviceRegistrationCode || device.deviceRegistrationCode?.includes(filters.deviceRegistrationCode))
      );
    });
    setFilteredDevices(filtered);
  }, [devices, filters]);

  const handleFilterChange = (filters) => {
    const { localDeviceFilters, hasNoLocationNew,...locationNewFilters } = filters;

    const filtered = devices.filter((device) => {
      const matchesName =
        !filters.deviceName || device.name?.toLowerCase().includes(filters.deviceName.toLowerCase());
      const matchesRegistration =
        filters.isRegistered === '' ||
        (filters.isRegistered === 'true' && device.serialNumber) ||
        (filters.isRegistered === 'false' && !device.serialNumber);
      
      // Филтриране по locationNew
      const matchesLocationName =
      !locationNewFilters.deviceName ||
      device.locationNew?.name?.toLowerCase().includes(locationNewFilters.deviceName.toLowerCase());

        // Филтриране по localDevice
      const matchesSerialNumber =
        !localDeviceFilters.sn ||
        device.sn?.toLowerCase().includes(localDeviceFilters.sn.toLowerCase());
      const matchesRegistered =
        localDeviceFilters.registered === '' ||
        (localDeviceFilters.registered === 'true' && device.registered) ||
        (localDeviceFilters.registered === 'false' && !device.registered);
      
        const matchesNoLocation = hasNoLocationNew ? !device.locationNew : true;

        return matchesLocationName && matchesSerialNumber && matchesRegistered && matchesName && matchesRegistration &&matchesNoLocation;
    });
    setFilteredDevices(filtered);
  };

  const handleDeviceClick = async (device) => {
    setSelectedDevice(device);
    try {
      const playlistData = await getDevicePlaylist(device.sn);
      setPlaylist(playlistData.playlist || []);
    } catch (error) {
      console.error('Error fetching playlist:', error);
      setPlaylist([]);
    }
  };

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>{typeof item === 'object' ? JSON.stringify(item, null, 2) : item}</li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object' && value !== null) {
      return <pre>{JSON.stringify(value, null, 2)}</pre>;
    } else {
      return value || 'N/A';
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSelectDevice = (deviceId) => {
    setSelectedDevices((prevSelected) =>
      prevSelected.includes(deviceId)
        ? prevSelected.filter((id) => id !== deviceId)
        : [...prevSelected, deviceId]
    );
  };

  const performAction = (action) => {
    console.log(`Performing action: ${action} on devices:`, selectedDevices);
    // Add your logic for different actions here
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDevices = filteredDevices.slice(indexOfFirstItem, indexOfLastItem);

  // if (loading) return <Typography>Loading devices...</Typography>;
  // if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Grid container spacing={3}>
      {/* Device list table */}
      <Grid item xs={12} sm={selectedDevice ? 6 : 12} md={selectedDevice ? 8 : 12}>
        <div className="device-list-container">
          <Card style={{ padding: '20px' }}>

            <DeviceFilters
              filters={filters}
              onFilterChange={(updatedFilters) => {
                setFilters(updatedFilters);
                handleFilterChange(updatedFilters); // Приложете новите филтри
              }}
            />
            <FormControlLabel
          control={<Checkbox checked={filters.hasNoLocationNew} onChange={(e) => setFilters({ ...filters, hasNoLocationNew: e.target.checked })} />}
          label="Show only devices without locationNew"
        />
            <Button 
        variant="contained" 
        color="primary" 
        onClick={() => performAction('Action 1')} 
        disabled={selectedDevices.length === 0}
        style={{ marginBottom: '10px' }}
      >
        Perform Action 1
      </Button>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={() => performAction('Action 2')} 
        disabled={selectedDevices.length === 0}
        style={{ marginLeft: '10px', marginBottom: '10px' }}
      >
        Perform Action 2
      </Button>

<Typography variant="h6" style={{ marginBottom: '10px' }}>
    Total Devices: {devices.length} | Filtered Devices: {filteredDevices.length}
  </Typography>
            <Typography variant="h6">Devices</Typography>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell><strong> Select</strong></TableCell>
                <TableCell><strong> Name</strong></TableCell>
                <TableCell><strong>Serial Number</strong></TableCell>
                  <TableCell><strong>Last Updated</strong></TableCell>
                  <TableCell><strong>Status</strong></TableCell>
                  <TableCell><strong>PmiLocation</strong></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {currentDevices.map((device) => (
                  <TableRow
                    key={device._id}
                    onClick={() => handleDeviceClick(device)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: selectedDevice?._id === device._id ? '#e0f7fa' : 'inherit',
                    }}
                  >
                    <TableCell>
                <Checkbox 
                  checked={selectedDevices.includes(device._id)} 
                  onChange={() => handleSelectDevice(device._id)}
                />
              </TableCell>
                    <TableCell>{device.name}</TableCell>
                     <TableCell>{device.sn}</TableCell>
                     <TableCell>
                      {new Date(device.updatedAt).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })}
                    </TableCell>
                    <TableCell>{device.registered ? 'Registered' : 'Not Registered'}</TableCell>
                    
                    <TableCell>{device.locationNew?.name || 'No Location'}</TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            
          </Card>
          <Pagination
        count={Math.ceil(filteredDevices.length / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
        </div>
      </Grid>

      {/* Device details and playlist */}
      {selectedDevice && (
      <Grid item xs={12} sm={6} md={4}>
        <div className="device-details-container">
          {selectedDevice && (
            <Card style={{ padding: '20px' }}>
              <Typography variant="h5" style={{ marginBottom: '10px' }}>
                {selectedDevice.locationNew?.posName || 'No Location Name'}
              </Typography>
              <Typography variant="h6">Device Details</Typography>
              <Button
                style={{
                  position: 'fixed',
                  top: '90px',
                  right: '20px',
                  zIndex: 1000,
                }}
                variant="outlined"
                color="secondary"
                onClick={() => setSelectedDevice(null)}
              >
                Close
              </Button>
              {Object.entries(selectedDevice).map(([key, value]) => (
                <Accordion key={key}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{key}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>{renderValue(value)}</AccordionDetails>
                </Accordion>
              ))}

              <Typography variant="h6" style={{ marginTop: '20px' }}>Playlist</Typography>
              {playlist.length > 0 ? (
                <div>
                  <Table>
                    <TableBody>
                      {playlist.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <a href={item.localPath} target="_blank" rel="noopener noreferrer">
                              {item.filename}
                            </a>
                          </TableCell>
                          <TableCell>{item.campaign}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <VideoPreviewPlaylist key={selectedDevice?.sn} playlist={playlist} />
                </div>
              ) : (
                <Typography>No Playlist Available</Typography>
              )}
            </Card>
          )}
        </div>
      </Grid>
      )}
    </Grid>
  );
}

export default Devices;
