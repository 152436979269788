import React from 'react';
import { Typography, Checkbox, Button } from '@mui/material';

const DynamicCheckboxGroup = ({ title, field, options, selectedValues, onChange, showCounts = false, disabled = false }) => {
  //console.log("Disabled status for", title, ":", disabled);

  const validSelectedValues = Array.isArray(selectedValues) ? selectedValues : [];
  const validOptions = Array.isArray(options) ? options : [];

  const allSelected = validSelectedValues.length === validOptions.length;

  const handleSelectAll = (checked) => {
    onChange(field, checked ? validOptions.map(o => o._id) : []);
  };

  const handleOptionChange = (optionId) => {
    if (disabled) return; // Ако е деактивирано, не правим нищо
    const updatedValues = selectedValues.includes(optionId)
      ? selectedValues.filter((item) => item !== optionId)
      : [...selectedValues, optionId];
    onChange(field, updatedValues);
  };
/*
  const handleGlobalSelectAll = () => {
    console.log('Global Select All clicked');
    console.log('Groups:', groups);
  
    const allSelected = groups.every(group => group.selectedValues.length === group.options.length);
    console.log('All selected:', allSelected);
  
    groups.forEach(group => {
      if (!group.options || !Array.isArray(group.options)) {
        console.error(`Invalid options for group: ${group.field}`);
        return;
      }
      const allIds = group.options.map(o => o._id);
      console.log(`Processing group: ${group.field}, All IDs:`, allIds);
      onChange(group.field, allSelected ? [] : allIds);
    });
  };
  */

  return (
    <div>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <label>
        <Checkbox
          checked={allSelected}
          onChange={(e) => handleSelectAll(e.target.checked)}
          disabled={disabled} // Disable the Select All checkbox if disabled is true
        />
        Select All
      </label>
      {validOptions.map((option, index) => (
        <label key={option._id || `unknown-${index}`}>
          <Checkbox
            checked={validSelectedValues.includes(option._id || 'Unknown')}
            onChange={() => handleOptionChange(option._id || 'Unknown')}
            disabled={disabled} // Disable individual checkboxes if disabled is true
          />
          {option._id || 'Unknown'} {showCounts && option.count !== undefined ? `(${option.count})` : ''}
        </label>
      ))}
    </div>
  );
};
const GlobalSelectAll = ({ groups = [], onChange }) => {
  const handleGlobalSelectAll = () => {
    const allSelected = groups.every(
      (group) => group.selectedValues.length === group.options.length && group.options.length > 0
    );

    groups.forEach((group) => {
      const allIds = group.options.map((o) => o._id);
      onChange(group.field, allSelected ? [] : allIds);
    });
  };

  return (
    <Button variant="contained" color="secondary" onClick={handleGlobalSelectAll}>
      Global Select All / Deselect All
    </Button>
  );
};

export { DynamicCheckboxGroup, GlobalSelectAll };

