import React, { useState, useEffect, useRef } from 'react';
import { getPublicPath } from '../utils/pathUtils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const VideoPreviewPlaylist = ({ playlist, device }) => {
 // console.log('Playlist:', playlist, 'Device:', device);
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  /*
    useEffect(() => {
  
      const handleNextMedia = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % playlist.length);
      };
  
      const currentMedia = playlist[currentIndex];
      const isImage = currentMedia?.fileType?.startsWith('image');
  
      if (isImage) {
        const timer = setTimeout(handleNextMedia, 5000); // 5 seconds for images
        return () => clearTimeout(timer);
      }
  const videoElement = videoRef.current; // Capture ref value
      if (videoElement) {
        videoElement.addEventListener('ended', handleNextMedia);
        return () => {
          if (videoElement) {
            videoElement.removeEventListener('ended', handleNextMedia);
          }
        };
      }
    }, [currentIndex, playlist]);
  
    */
    const handleThisMedia = (index) => {
      console.log('Switching to next media...');
      console.log(index)
      setCurrentIndex(index);
    };
    const handleNextMedia = () => {
      console.log('Switching to next media...');
      setCurrentIndex((prevIndex) => (prevIndex + 1) % playlist.length);
    };
  useEffect(() => {
   // console.log('Current media:', playlist[currentIndex]?.filename);

    
    
    console.log(currentIndex)
    const currentMedia = playlist[currentIndex];
    const isImage = currentMedia?.fileType?.startsWith('image');

    if (isImage) {
      console.log('Displaying image, switching in 5 seconds...');
      const timer = setTimeout(handleNextMedia, 5000);
      return () => clearTimeout(timer);
    }

    const videoElement = videoRef.current;
    if (videoElement) {
   //   console.log('Playing video:', currentMedia.filename);
      videoElement.pause(); // Спираме текущото видео преди смяна
      videoElement.src = getPublicPath(currentMedia.localPath || ''); // Презареждаме източника
      videoElement.load(); // Принуждаваме браузъра да презареди видеото
      videoElement.play().catch((err) => console.error('Error playing video:', err));

      videoElement.addEventListener('ended', handleNextMedia);

      return () => {
        videoElement.removeEventListener('ended', handleNextMedia);
      };
    }
  }, [currentIndex]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play().catch((err) => {
        console.error('Error playing video:', err);
      });
    }
  }, [currentIndex]);

  const currentMedia = playlist[currentIndex];

  if (!currentMedia) {
    return <p>No media available in the playlist.</p>;
  }

  const isImage = currentMedia.fileType?.startsWith('image');
  const isVideo = currentMedia.fileType?.startsWith('video');
  const mediaType = currentMedia.fileType || 'video/mp4';
  const previewPath = getPublicPath(currentMedia.localPath || '');

  return (
    <div style={{ maxWidth: '1500px', margin: '0 auto', textAlign: 'center' }}>
      {/* Таблица с информация за устройството */}
      <div sx={{ display: { xs: 'block', sm: 'none' } }} style={{textAlign: "left"}}>{device?.posName}</div>
      <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto', marginBottom: '10px' }}>

        <Table size="small" sx={{ display: { xs: 'none', sm: 'table' } }}>
          <TableHead>
            <TableRow>
              <TableCell><strong>REEV</strong></TableCell>
              <TableCell><strong>POS Name</strong></TableCell>
              <TableCell><strong>Trade Segment</strong></TableCell>
              <TableCell><strong>KA NAme</strong></TableCell>
              <TableCell><strong>POS Type</strong></TableCell>
              <TableCell><strong>Placement</strong></TableCell>
              <TableCell><strong>Manned</strong></TableCell>
              <TableCell><strong>Tier</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{device?.name || 'N/A'}</TableCell>
              <TableCell>{device?.posName || 'N/A'}</TableCell>
              <TableCell>{device?.tradeSegment || 'N/A'}</TableCell>
              <TableCell>{device?.kaName || 'N/A'}</TableCell>
              <TableCell>{device?.posTypeNew || 'N/A'}</TableCell>
              <TableCell>{device?.placementNew || 'N/A'}</TableCell>
              <TableCell>{device?.manned || 'N/A'}</TableCell>
              <TableCell>{device?.tier || 'N/A'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Key Visuals */}
      {playlist.map((media, index) => (
        media.keyVisual && (
          <img
            key={`keyVisual-${index}`}
            src={media.keyVisual}
            alt={`Key Visual for ${media.campaign}`}
            style={{ width: '20%', maxHeight: '80px', objectFit: 'contain', cursor: "pointer" }}
            onClick={() => handleThisMedia(index)}
          />
        )
      ))}

      {/* Таблица с информация за кампанията */}
      <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto', marginBottom: '10px' }}>

        <Table size="small" sx={{ display: { xs: 'none', sm: 'table' } }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 150, whiteSpace: 'nowrap' }}><strong>Campaign Name</strong></TableCell>
              <TableCell sx={{ minWidth: 150, whiteSpace: 'nowrap' }}><strong>Category</strong></TableCell>

              <TableCell sx={{ minWidth: 120, whiteSpace: 'nowrap' }}><strong>Start Date</strong></TableCell>
              <TableCell sx={{ minWidth: 120, whiteSpace: 'nowrap' }}><strong>End Date</strong></TableCell>
              <TableCell sx={{ minWidth: 150, whiteSpace: 'nowrap' }}><strong>Trade Segments</strong></TableCell>
              <TableCell sx={{ minWidth: 150, whiteSpace: 'nowrap' }}><strong>KA Name</strong></TableCell>
              <TableCell sx={{ minWidth: 120, whiteSpace: 'nowrap' }}><strong>POS Type</strong></TableCell>
              <TableCell sx={{ minWidth: 150, whiteSpace: 'nowrap' }}><strong>Camp Placements</strong></TableCell>
              <TableCell sx={{ minWidth: 120, whiteSpace: 'nowrap' }}><strong>Manned</strong></TableCell>
              <TableCell sx={{ minWidth: 100, whiteSpace: 'nowrap' }}><strong>Tier</strong></TableCell>
              <TableCell sx={{ minWidth: 150, whiteSpace: 'nowrap' }}><strong>File Placements</strong></TableCell>
              <TableCell sx={{ minWidth: 150, whiteSpace: 'nowrap' }}><strong> File Categories</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{currentMedia.campaign || 'N/A'}</TableCell>
              <TableCell>{currentMedia.campaignCategory || 'N/A'}</TableCell>
              <TableCell>{currentMedia.campaignStartDate ? new Date(currentMedia.campaignStartDate).toLocaleDateString() : 'N/A'}</TableCell>
              <TableCell>{currentMedia.campaignEndDate ? new Date(currentMedia.campaignEndDate).toLocaleDateString() : 'N/A'}</TableCell>
              <TableCell>{currentMedia.campaignTradeSegment || 'N/A'}</TableCell>
              <TableCell>{currentMedia.campaignKaName || 'N/A'}</TableCell>
              <TableCell>{currentMedia.campaignPosType || 'N/A'}</TableCell>
              <TableCell>{currentMedia.campaignPlacement || 'N/A'}</TableCell>
              <TableCell>{currentMedia.campaignManned || 'N/A'}</TableCell>
              <TableCell>{currentMedia.campaignTire || 'N/A'}</TableCell>
              <TableCell>{currentMedia.placements?.join(', ') || 'N/A'}</TableCell>
              <TableCell>{currentMedia.categories || 'N/A'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {isImage && (
        <img
          src={previewPath}
          alt={currentMedia.filename}
          style={{ width: '60%', height: 'auto' }}
        />
      )}

      {isVideo && (
        <video
          ref={videoRef}
          controls
          autoPlay
          muted
          style={{ width: '100%', height: 'auto', maxWidth: "700px" }}
        >
          <source src={previewPath} type={mediaType} />
          Your browser does not support the video tag.
        </video>
      )}

      {!isImage && !isVideo && <p>Unsupported media type</p>}

      <div style={{ marginTop: '5px' }}>
        <p style={{margin: "10px 0"}}><strong>Campaign Name:</strong> {currentMedia.campaign || 'N/A'}</p>
        <p style={{margin: "10px 0"}}><strong>Filename:</strong> {currentMedia.filename ? decodeURIComponent(currentMedia.filename) : 'N/A'}</p>
        <p style={{margin: "10px 0"}}><strong>Valid From:</strong> {currentMedia.validFrom || 'N/A'}</p>
        <p style={{margin: "10px 0"}}><strong>Valid To:</strong> {currentMedia.validTo || 'N/A'}</p>
        <p style={{margin: "10px 0"}}><strong>Orientation:</strong> {currentMedia.orientation || 'N/A'}</p>
      </div>
    </div>
  );
};

export default VideoPreviewPlaylist;
