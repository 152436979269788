import React, { useState, useEffect } from 'react';
import { generateUploadUrl } from '../services/digitalOceanService';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { TextField } from '@mui/material';
import { FormControlLabel, Checkbox } from '@mui/material';

import socket from '../socket'; // 🔹 Използваме WebSocket модула

console.log("WebSocket is trying to connect to:", socket.io.uri);

// Скрити стилове за input
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const UploadFile = ({ onUploadComplete = () => { }, context = {} }) => {
    const [files, setFiles] = useState([]); // Списък с избрани файлове
    const [progresses, setProgresses] = useState({}); // Прогрес за всеки файл
    const [validFrom, setValidFrom] = useState('');
    const [validTo, setValidTo] = useState('');
    const [fileValidity, setFileValidity] = useState({}); // Индивидуални дати за всеки файл
    const [uploadStatus, setUploadStatus] = useState({});
    const [variations, setVariations] = useState({
        "90": true,
        "-90": true,
        "180": true,
    });
    const [rotationOptions, setRotationOptions] = useState({
        "90": true,
        "-90": true,
        "180": true,
    });
    const [globalRotation, setGlobalRotation] = useState({});
    const [uploadStatuses, setUploadStatuses] = useState({});
    const [fileHashes, setFileHashes] = useState({});
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        socket.on('upload-status', (data) => {
            setUploadStatus((prev) => ({
                ...prev,
                [data.fileName]: data.message
            }));
        });

        return () => {
            socket.off('upload-status');
        };
    }, []);

    const checkStatuses = async () => {
        try {
            const hashes = Object.values(fileHashes); // Вземаме всички хешове
            //   console.log('Checking statuses for file hashes:', hashes);

            const response = await fetch('/api/media/check-upload-status', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ fileHashes: hashes }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch upload statuses');
            }

            const statuses = await response.json();
            //   console.log("Statuses from backend:", statuses);

            const updatedStatuses = files.reduce((acc, file) => {
                // Вземаме хеша за текущия файл
                const hash = fileHashes[file.name];
                //   console.log(`Checking file: ${file.name}, Hash: ${hash}`);

                // Търсим статуса в отговора от бекенда
                const fileStatus = statuses.find((status) => status.fileHash === hash);
                if (fileStatus) {
                    //  console.log(`Status found for ${file.name}:`, fileStatus);
                } else {
                    console.log(`No status found for ${file.name}`);
                }

                // Актуализираме обекта с информация за статуса
                acc[file.name] = fileStatus
                    ? {
                        status: fileStatus.status,
                        message: fileStatus.message,
                    }
                    : {
                        status: 'Pending',
                        message: 'No status available',
                    };

                return acc;
            }, {});

            // console.log("Updated statuses:", updatedStatuses);

            setUploadStatuses((prevStatuses) => ({
                ...prevStatuses,
                ...updatedStatuses,
            }));
        } catch (error) {
            console.error('Error fetching upload statuses:', error);
        }
    };

    /*
        // Периодична Проверка на статусите на качените файлове
        useEffect(() => {
            const interval = setInterval(async () => {
                if (files.length === 0) return; // Няма файлове за проверка
        
                const fileHashes = files.map((file) => file.hash);
                console.log('Checking statuses for file hashes:', fileHashes);
        
                const updatedStatuses = await checkStatuses(fileHashes);
        
                setUploadStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    ...updatedStatuses,
                }));
            }, 5000);
        
            return () => clearInterval(interval);
        }, [files]);
        */
    useEffect(() => {
        if (files.length === 0) { // || Object.keys(uploadStatuses).every((fileName) => uploadStatuses[fileName]?.status === 'complete')) {
            return; // Няма нужда от нови заявки, ако всички файлове са обработени
        }
        /*

        if (!isUploading) {
            return;
        }
            */
        console.log('is uploading:', isUploading);
        const interval = setInterval(async () => {
            //  if (files.length === 0) return; // Няма файлове за проверка
            await generateHashesForFiles(); // Генерираме хешове
            await checkStatuses(); // Проверяваме статусите
        }, 5000);
        /*
                if (!isUploading) {
                    return;
                }
                */
        return () => clearInterval(interval);
    }, [files]);

    React.useEffect(() => {
        //   console.log('Component updated with statuses:', uploadStatuses);
    }, [uploadStatuses]);

    /*
        const handleFileChange = async (event) => {
            const selectedFiles = Array.from(event.target.files);
        
            const filesWithHashes = await Promise.all(
                selectedFiles.map(async (file) => {
                    if (typeof file.arrayBuffer !== 'function') {
                        console.error('File object does not support arrayBuffer:', file);
                        return null;
                    }
                    const hash = await generateFileHash(file);
                    return { ...file, hash }; // Добавяме hash към обекта file
                })
            );
        
            // Филтрираме само валидните файлове
            setFiles((prevFiles) => [
                ...prevFiles,
                ...filesWithHashes.filter((file) => file !== null),
            ]);
        };
        */
    const handleFileChange = async (event) => {
        const selectedFiles = Array.from(event.target.files);

        // Генерираме хешове и ги съхраняваме в отделен обект
        const newFileHashes = {};
        await Promise.all(
            selectedFiles.map(async (file) => {
                if (typeof file.arrayBuffer !== 'function') {
                    console.error('File object does not support arrayBuffer:', file);
                    return;
                }
                const hash = await generateFileHash(file);
                newFileHashes[file.name] = hash;
            })
        );

        // Актуализираме обекта с хешове
        setFileHashes((prevHashes) => ({
            ...prevHashes,
            ...newFileHashes,
        }));

        // Добавяме новите файлове към състоянието
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

        // Инициализираме валидност за новите файлове
        const newFileValidity = {};
        selectedFiles.forEach((file) => {
            newFileValidity[file.name] = {
                validFrom: '',
                validTo: '',
            };
        });

        setFileValidity((prevValidity) => ({
            ...prevValidity,
            ...newFileValidity,
        }));
    };


    const handleVariationChange = (rotation) => {
        setVariations((prev) => ({
            ...prev,
            [rotation]: !prev[rotation],
        }));
    };
    const handleRotationChange = (fileName, rotation) => {
        setRotationOptions({
            ...rotationOptions,
            [fileName]: {
                ...rotationOptions[fileName],
                [rotation]: !rotationOptions[fileName]?.[rotation],
            },
        });
    };

    const handleGlobalRotationChange = (rotation) => {
        setGlobalRotation({
            ...globalRotation,
            [rotation]: !globalRotation[rotation],
        });

        // Apply global rotation settings to all files
        const updatedRotationOptions = { ...rotationOptions };
        files.forEach((file) => {
            updatedRotationOptions[file.name] = {
                ...updatedRotationOptions[file.name],
                [rotation]: !globalRotation[rotation],
            };
        });
        setRotationOptions(updatedRotationOptions);
    };

    const generateFileHash = async (file, who) => {
        //   console.log('Generating hash for file:', file, 'who:', who);
        if (typeof file.arrayBuffer !== 'function') {
            throw new Error('Invalid file object passed to generateFileHash');
        }
        const arrayBuffer = await file.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
        return Array.from(new Uint8Array(hashBuffer)).map(byte => byte.toString(16).padStart(2, '0')).join('');
    };

    const generateHashesForFiles = async () => {
        const hashes = {};
        for (const file of files) {
            try {
                const hash = await generateFileHash(file);
                hashes[file.name] = hash;
            } catch (error) {
                console.error(`Error generating hash for file ${file.name}:`, error);
            }
        }
        setFileHashes(hashes);
    };

    const checkExistingFiles = async (fileHashes) => {
        const response = await axios.post('/api/media/check-files', { fileHashes });
        return response.data.existingFiles;
    };

    const handleUpload = async () => {
        if (files.length === 0) {
            alert('Please select at least one file!');
            return;
        }
        setIsUploading(true); // Стартираме качването
        const updatedFiles = [...files]; // Копие на текущите файлове

        try {
            const uploadedFiles = [];
            // Генериране на хешове за избраните файлове
            const fileHashes = await Promise.all(files.map(generateFileHash));
            console.log('Generated file hashes:', fileHashes);
            const existingFiles = await checkExistingFiles(fileHashes);

            // Филтриране на новите файлове
            const newFiles = files.filter(
                (file, index) => !existingFiles.some(existing => existing.fileHash === fileHashes[index])
            );

            if (newFiles.length === 0) {
                alert('All selected files are already uploaded.');
                return;
            }

            const newProgresses = { ...progresses };

            // Качване и запис в базата
            await Promise.all(
                newFiles.map(async (file) => {
                    console.log('Uploading file:', file.name, 'type:', file.type);
                    console.log(`Selected rotations:`, Object.keys(variations).filter((key) => variations[key]));

                    // Генериране на подписан URL
                    const { url, fileName } = await generateUploadUrl(file.name, file.type);

                    // Качване в DigitalOcean Spaces
                    await axios.put(url, file, {
                        headers: {
                            'Content-Type': file.type,
                            'x-amz-meta-original-filename': encodeURIComponent(file.name),
                            'x-amz-meta-file-hash': await generateFileHash(file), // Добавяне на хеша на файла
                            'x-amz-acl': 'public-read', // ✅ Задаване на публичен достъп
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            newProgresses[file.name] = percentCompleted;
                            setProgresses({ ...newProgresses });
                        },
                    });

                    console.log(`File uploaded successfully: ${fileName}`);

                    // Запис в базата
                    const fileHash = await generateFileHash(file, 'save to db');
                    const fileValidFrom = fileValidity[file.name].validFrom || validFrom;
                    const fileValidTo = fileValidity[file.name].validTo || validTo;
                    const baseUrl = url.split('?')[0]; // Remove query parameters
                    const uploadedFile = await axios.post('/api/media/upload-file', {
                        fileName: file.name,
                        fileType: file.type,

                        purpose: 'media', // Или друго предназначение
                        fileSize: file.size,
                        fileHash,
                        url: baseUrl, // Save only the base URL
                        validFrom: fileValidFrom,
                        validTo: fileValidTo,
                        campaignId: context.campaignId || null, // Добавяме ID на кампанията
                        variations: Object.keys(variations).filter((key) => variations[key]),

                    });
                    // Актуализираме файла с информацията от бекенда
                    console.log('Uploaded file:', uploadedFile.data.file.thumbnailPath);
                    // Проверка и обработка на thumbnailPath
                    // Проверка и извличане на thumbnailPath
                    let thumbnailPath = null;

                    if (uploadedFile?.data?.file?.thumbnailPath) {
                        thumbnailPath = `https://on-labs.eu/thumb/${uploadedFile.data.file.thumbnailPath.split('/').pop()}`;
                        console.log('Generated thumbnail URL:', thumbnailPath);
                    } else {
                        console.warn(`ThumbnailPath is missing for file: ${file.name}`);
                    }

                    if (!thumbnailPath) {
                        console.warn(`No thumbnail generated for file: ${file.name}`);
                    }

                    // Обновяване на файла с информацията от бекенда
                    const updatedFile = {
                        ...file,
                        thumbnailPath,
                    };
                    uploadedFiles.push(updatedFile)//(uploadedFile.data);
                    // Актуализираме информацията за файла
                    const index = updatedFiles.findIndex((f) => f.name === file.name);
                    if (index !== -1) {
                        updatedFiles[index] = updatedFile;
                    }
                })
            );
            // Обновяваме състоянието на файловете
            setFiles(updatedFiles);
            console.log("Uploaded files:", uploadedFiles);

            if (typeof onUploadComplete === 'function') {
                onUploadComplete(uploadedFiles);
            } else {
                console.warn("onUploadComplete is not a function. Ensure it is passed as a prop.");
            }
            alert('Files uploaded successfully!');

        } catch (error) {
            console.error('Error during file upload:', error);
            alert('Failed to upload one or more files.');
        } finally {
            setIsUploading(false); // Завършване на процеса на качване
        }
    };

    const handleFileValidityChange = (fileName, field, value) => {
        setFileValidity((prev) => ({
            ...prev,
            [fileName]: { ...prev[fileName], [field]: value }
        }));
    };
    const handleGlobalValidityChange = (field, value) => {
        if (field === 'validFrom') {
            setValidFrom(value);
        } else if (field === 'validTo') {
            setValidTo(value);
        }

        // Автоматично задаване на валидност за всички файлове
        setFileValidity((prev) => {
            const updatedValidity = { ...prev };
            Object.keys(updatedValidity).forEach((fileName) => {
                updatedValidity[fileName][field] = value; // Задаваме новата стойност за валидност
            });
            return updatedValidity;
        });
    };

    {
        files.map((file) => {
            const fileHash = file.hash || fileHashes[file.name]; // Проверка за хеша
            const fileStatus = uploadStatuses[fileHash] || {
                status: 'Pending',
                message: 'No status available',
            };

            //console.log(`Rendering file: ${file.name}`);
            //console.log(`File hash: ${fileHash}`);
            //console.log(`File status:`, fileStatus);

            return (
                <div key={file.name} style={{ marginBottom: '1rem' }}>
                    <Typography variant="subtitle1">
                        Rendering file: {file.name} {/* Този текст ще се покаже за всеки файл */}
                    </Typography>
                    <Typography variant="body2" style={{ color: 'gray' }}>
                        This section is working!
                    </Typography>
                </div>
            );
        })
    }


    return (

        <div>
            {/* Заглавие */}
            <Typography variant="h4" gutterBottom>
                Upload Content
            </Typography>

            {files.map((file) => {
                const fileHash = file.hash || fileHashes[file.name]; // Проверка за хеш
                const fileStatus = uploadStatuses[file.name] || {
                    status: 'Pending',
                    message: 'No status available',
                };

                //  console.log(`Rendering file: ${file.name}`);
                //  console.log(`File hash: ${fileHash}`);
                //  console.log(`File status:`, fileStatus);
                // Извличане на тъмб, ако съществува
                const thumbnailUrl = uploadStatuses[fileHash]?.thumbnailPath
                    ? `https://on-labs.eu/thumb/${uploadStatuses[fileHash]?.thumbnailPath.split('/').pop()}`
                    : null;

                return (
                    <div key={file.name} style={{ marginBottom: '1rem' }}>
                        <Typography variant="subtitle1">
                            {file.name} - <strong>{fileStatus.status}</strong>
                        </Typography>
                        <Typography variant="body2" style={{ color: 'gray' }}>
                            {fileStatus.message}
                        </Typography>
                        {file.thumbnailPath && (
                            <img
                                src={file.thumbnailPath}
                                alt={`Thumbnail for ${file.name}`}
                                style={{ marginTop: '0.5rem', maxWidth: '200px', maxHeight: '150px' }}
                            />
                        )}
                    </div>
                );
            })}

            {/* Поле за избор на файлове */}
            <label htmlFor="file-input">
                <VisuallyHiddenInput
                    id="file-input"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                />
                <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                >
                    Select Files
                </Button>
            </label>

            {/* Поле за обща валидност */}
            {files.length > 0 && (
                <div style={{ marginTop: '1rem' }}>
                    <Typography variant="h6">Global Validity (All Files):</Typography>
                    <TextField
                        label="Valid From"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={validFrom}
                        onChange={(e) => handleGlobalValidityChange('validFrom', e.target.value)}
                        style={{ marginRight: '1rem', marginTop: "1rem" }}
                    />
                    <TextField
                        label="Valid To"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={validTo}
                        onChange={(e) => handleGlobalValidityChange('validTo', e.target.value)}
                        style={{ marginTop: "1rem" }}
                    />
                </div>
            )}

            {files.length > 0 && (
                <div style={{ marginTop: '1rem' }}>
                    <Typography variant="h6">Global Rotation Options (All Files):</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={globalRotation['90'] || false}
                                onChange={() => handleGlobalRotationChange('90')}
                            />
                        }
                        label="90°"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={globalRotation['-90'] || false}
                                onChange={() => handleGlobalRotationChange('-90')}
                            />
                        }
                        label="-90°"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={globalRotation['180'] || false}
                                onChange={() => handleGlobalRotationChange('180')}
                            />
                        }
                        label="180°"
                    />
                </div>
            )}

            {/* Списък с избрани файлове */}
            {files.length > 0 && (

                <div>
                    <Typography variant="h6" style={{ marginTop: '1rem' }}>
                        Selected Files:
                    </Typography>
                    <ul style={{ paddingLeft: 0 }}>
                        {files.map((file) => (
                            <li key={file.name} style={{ marginBottom: '1rem', display: "flex", alignItems: "center", width: "100%" }}>
                                <div style={{ marginRight: "15px", width: "calc(100% - 400px)", maxWidth: "500px" }}>
                                    {/* Име и прогрес на качването */}
                                    {file.name} - {progresses[file.name] || 0}% uploaded

                                    <LinearProgress variant="determinate" value={progresses[file.name] || 0} />

                                    {/* Добавяне на статус съобщение */}
                                    <Typography variant="body2" color="textSecondary">
                                        {uploadStatus[file.name] || 'Waiting...'}
                                    </Typography>
                                </div>

                                {/* Полета за индивидуална валидност */}
                                <div style={{ marginTop: '0.5rem' }}>
                                    <TextField
                                        label="Valid From"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        value={fileValidity[file.name]?.validFrom || ''}
                                        onChange={(e) =>
                                            handleFileValidityChange(file.name, 'validFrom', e.target.value)
                                        }
                                        style={{ marginRight: '1rem' }}
                                    />
                                    <TextField
                                        label="Valid To"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        value={fileValidity[file.name]?.validTo || ''}
                                        onChange={(e) =>
                                            handleFileValidityChange(file.name, 'validTo', e.target.value)
                                        }
                                    />
                                </div>
                                <div style={{ marginBottom: '0.5rem' }}>
                                    <Typography variant="subtitle1">Rotation Variations:</Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rotationOptions[file.name]?.['90'] || false}
                                                onChange={() => handleRotationChange(file.name, '90')}
                                            />
                                        }
                                        label="90°"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rotationOptions[file.name]?.['-90'] || false}
                                                onChange={() => handleRotationChange(file.name, '-90')}
                                            />
                                        }
                                        label="-90°"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rotationOptions[file.name]?.['180'] || false}
                                                onChange={() => handleRotationChange(file.name, '180')}
                                            />
                                        }
                                        label="180°"
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>



                    {/* Бутон за качване */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        style={{ marginTop: '1rem' }}
                    >
                        Upload All
                    </Button>
                </div>
            )}
        </div>
    );
};

export default UploadFile;