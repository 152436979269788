import React, { useState, useEffect } from 'react';
import { MenuItem, Select, InputLabel, FormControl, Checkbox, ListItemText, Typography,Button,TextField} from '@mui/material';
import { 
    fetchTradeSegmentOptions, 
    fetchKaNameOptions, 
    fetchPosTypetOptions, 
    fetchPlacementOptions, 
    fetchMannedOptions, 
    fetchTireOptions, 
    fetchScreeTypeOptions 
} from '../services/api';

import './DeviceFilters.css';
//import { set } from 'mongoose';

const DeviceFilters = ({ filters,onFilterChange }) => {
    const [tradeSegmentOptions, setTradeSegmentOptions] = useState([]);
    const [kaNameOptions, setKaNameOptions] = useState([]);
    const [posTypeOptions, setPosTypeOptions] = useState([]);
    const [placementOptions, setPlacementOptions] = useState([]);
    const [mannedOptions, setMannedOptions] = useState([]);
    const [tireOptions, setTireOptions] = useState([]);
    const [screenTypeOptions, setScreenTypeOptions] = useState([]);
    const [isActiveOptions, setisActiveTypeOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [localFilters, setLocalFilters] = useState({
        ...filters,
        localDeviceFilters: {
            name: '',
            sn: '',
            registered: '',
            activeMac: '',
        },
    });

   /*
    const [filters, setFilters] = useState({
        tradeSegment: [],
        kaName: [],
        posType: [],
        placementNew: [],
        manned: [],
        tire: [],
        screenType: [],
        deviceName: '',
        isRegistered: '',
        isActive: ''
    });
    */
    const [isKaNameActive, setIsKaNameActive] = useState(false);

    useEffect(() => {
        // Зареждане на опции за филтрите
        const loadOptions = async () => {
            try {
                const [tradeSegmentData, kaNameData, posTypeData, placementData, mannedData, tireData, screenTypeData] = await Promise.all([
                    fetchTradeSegmentOptions(),
                    fetchKaNameOptions(),
                    fetchPosTypetOptions(),
                    fetchPlacementOptions(),
                    fetchMannedOptions(),
                    fetchTireOptions(),
                    fetchScreeTypeOptions(),
                ]);
                setTradeSegmentOptions(tradeSegmentData.data.map(option => option._id || 'Unknown'));
                setKaNameOptions(kaNameData.data.map(option => option._id || 'Unknown'));
                setPosTypeOptions(posTypeData.data.map(option => option._id || 'Unknown'));
                setPlacementOptions(placementData.data.map(option => option._id || 'Unknown'));
                setMannedOptions(mannedData.data.map(option => option._id || 'Unknown'));
                setTireOptions(tireData.data.map(option => option._id || 'Unknown'));
                setScreenTypeOptions(screenTypeData.data.map(option => option._id || 'Unknown'));

            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };
        loadOptions();
    }, []);

    useEffect(() => {
        console.log('Is KA Name Active updated:', isKaNameActive);
    }, [isKaNameActive]);

    useEffect(() => {
        console.log('Filters updated:', filters);
    }, [filters]);
    
    useEffect(() => {
        setLocalFilters(filters); // Обновете локалното състояние
    }, [filters]);

    useEffect(() => {
        console.log('Filters applied:', localFilters);
        onFilterChange(localFilters); // Уверете се, че филтрите се прилагат при промяна
    }, [localFilters]);
    
    const handleMultiSelectChange = (event, field) => {
        const { value } = event.target;
        const updatedFilters = { ...filters, [field]: value };
        // Проверка за KEY ACCOUNT в tradeSegment
        if (field === 'tradeSegment') {
            const isActive = value.includes("KEY ACCOUNT");
            setIsKaNameActive(isActive);
            if (!isActive) {
                updatedFilters.kaName = []; // Нулиране на KA Name
            }
        }

        setLocalFilters(updatedFilters);
        onFilterChange(updatedFilters);

        console.log('Field:', field, 'Value:', value);
        console.log('Updated filters:', updatedFilters);

    };

    const renderMultiSelect = (label, options, field) => {
        
        if (!options || options.length === 0) {
            return <Typography variant="body2">{label} options are not available.</Typography>;
        }
        const isAllSelected = filters[field].length === options.length;

    const handleAllToggle = () => {
        const newValue = isAllSelected ? [] : options; // Ако е избрано "All", изберете всички, иначе изчистете
        setLocalFilters({ ...filters, [field]: newValue });
        onFilterChange({ ...filters, [field]: newValue });
    };
        return (
            <FormControl style={{ margin: '10px', minWidth: '200px' }}>
                <InputLabel>{label}</InputLabel>
                 
                <Select
                    multiple
                    value={filters[field] || []}
                    onChange={(e) => handleMultiSelectChange(e, field)}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {/* "All" опция */}
                <MenuItem value="all">
                    <Checkbox
                        checked={isAllSelected}
                        onChange={handleAllToggle}
                    />
                    <ListItemText primary="All" />
                </MenuItem>
                    {options.map((option, idx) => (
                        <MenuItem key={idx} value={option}>
                            <Checkbox checked={filters[field].includes(option)} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const clearAllFilters = () => {
        const clearedFilters = {
            tradeSegment: [],
            kaName: [],
            posType: [],
            placementNew: [],
            manned: [],
            tire: [],
            screenType: [],
            deviceName: '',
            isRegistered: '',
            localDeviceFilters: {
                name: '',
                sn: '',
                registered: '',
                activeMac: '',
            },
        };
        setLocalFilters(clearedFilters);
        onFilterChange(clearedFilters);
    
        console.log('Filters cleared:', clearedFilters); // Диагностика
    };
    
    const handleFilterChange = (e, scope = 'locationNew') => {
        const { name, value } = e.target;
        const updatedFilters = { ...localFilters };

        if (scope === 'locationNew') {
            updatedFilters[name] = value;
        } else if (scope === 'localDevice') {
            updatedFilters.localDeviceFilters[name] = value;
        }

    setLocalFilters(updatedFilters);
    onFilterChange(updatedFilters);

        console.log('Updated Filters:', updatedFilters); // Диагностика
    };


    return (
        <div className="device-filters">
            <h3>Filter Devices</h3>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Button
                variant="outlined"
                color="secondary"
                onClick={clearAllFilters}
            >
                Clear All Filters
            </Button>
        </div> 
            <div>
                <label>Name:</label>
                <input
                    type="text"
                    name="deviceName"
                    value={localFilters.deviceName || ''}
                    onChange={(e) => handleFilterChange(e)}
                    placeholder="Search by name"
                />
            </div>
            <div>
                <label>Serial Number:</label>
                <input
                    type="text"
                    name="sn"
                    value={localFilters.localDeviceFilters.sn || ''}
                    onChange={(e) => handleFilterChange(e, 'localDevice')}
                    placeholder="Search by Serial Number"
                />
            </div>
            <div>
                <label>Registration Status:</label>
                <select
                    name="isRegistered"
                    value={localFilters.localDeviceFilters.registered || ''}
                    onChange={(e) => handleFilterChange(e)}
                >
                    <option value="">All</option>
                    <option value="true">Registered</option>
                    <option value="false">Not Registered</option>
                </select>
            </div>

            {/* Новите мултиселекти */}
            {renderMultiSelect('Trade Segment', tradeSegmentOptions, 'tradeSegment')}
            {/* KA Name е активен само ако е избран Key Account */}
            {isKaNameActive
                ? renderMultiSelect('KA Name', kaNameOptions, 'kaName')
                : <Typography variant="body2">Select "KEY ACCOUNT" in Trade Segment to enable KA Name.</Typography>
            }
            {renderMultiSelect('POS Type', posTypeOptions, 'posType')}
            {renderMultiSelect('Placement', placementOptions, 'placementNew')}
            {renderMultiSelect('Manned', mannedOptions, 'manned')}
            {renderMultiSelect('Tire', tireOptions, 'tire')}
            {renderMultiSelect('Screen Type', screenTypeOptions, 'screenType')}
        </div>
    );

};

export default DeviceFilters;
