// CampaignFilters.js
import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Checkbox, ListItemText } from '@mui/material';

const CampaignFilters = ({ onFilterChange, options, multiSelect = false }) => {
    const [filters, setFilters] = useState(
        Object.fromEntries(Object.keys(options).map((key) => [key, multiSelect ? [] : '']))
    );

    useEffect(() => {
        onFilterChange(filters);
    }, [filters]);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        if (multiSelect) {
            setFilters((prev) => ({
                ...prev,
                [name]: typeof value === 'string' ? value.split(',') : value,
            }));
        } else {
            setFilters((prev) => ({ ...prev, [name]: value }));
        }
    };

    const clearFilters = () => {
        setFilters(
            Object.fromEntries(Object.keys(options).map((key) => [key, multiSelect ? [] : '']))
        );
    };

    return (
        <div>
            <Button variant="outlined" color="secondary" onClick={clearFilters} style={{ marginTop: '12px', marginRight: '10px', }}>
                Clear All Filters
            </Button>
            {Object.entries(options).map(([key, values]) => (
                <FormControl key={key} style={{ marginTop: '10px', marginRight: '10px', minWidth: '200px' }} size='small'>
                    <InputLabel>{key}</InputLabel>
                    <Select
                        name={key}
                        value={filters[key]}
                        onChange={handleFilterChange}
                        multiple={multiSelect}
                        renderValue={(selected) => (Array.isArray(selected) ? selected.join(', ') : selected)}
                    >
                        {multiSelect && (
                            <MenuItem value="">
                                <Checkbox checked={filters[key].length === 0} />
                                <ListItemText primary="All" />
                            </MenuItem>
                        )}
                        {values.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {multiSelect && <Checkbox checked={filters[key]?.includes(option)} />}
                                <ListItemText primary={option === 'Empty' ? 'No Value' : option} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ))}
        </div>
    );
};

export default CampaignFilters;
