import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography, 
  Card,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails, 
  Button
} from '@mui/material';



function Instructions() {
 

  return (
    <div >
      <Typography variant="h4" gutterBottom className="ppp">
        Инструкции
      </Typography>

      <Accordion>
        <AccordionSummary
         
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="span">Инсталиране на SD карти</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div>
                <img src="/img/instructions/instalationSD-1.jpg" alt="instalationSD-1" style={{width:"1100px", maxWidth: "100%"}} />
            </div>
            <div><img src="/img/instructions/instalationSD-2.jpg" alt="instalationSD-2" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div> <img src="/img/instructions/instalationSD-3.jpg" alt="instalationSD-3" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div><img src="/img/instructions/instalationSD-4.jpg" alt="instalationSD-4" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div><img src="/img/instructions/instalationSD-5.jpg" alt="instalationSD-5" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div><img src="/img/instructions/instalationSD-6.jpg" alt="instalationSD-6" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div><img src="/img/instructions/instalationSD-7.jpg" alt="instalationSD-6" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div><img src="/img/instructions/instalationSD-8.jpg" alt="instalationSD-6" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div><img src="/img/instructions/instalationSD-9.jpg" alt="instalationSD-6" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div><img src="/img/instructions/instalationSD-10.jpg" alt="instalationSD-6" style={{width:"1100px", maxWidth: "100%"}} /></div>
            
            
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
         
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="span">Регистрация на нов обект в системата</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div><img src="/img/instructions/addDevice1.jpg" alt="instalationSD-1" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div><img src="/img/instructions/addDevice2.jpg" alt="instalationSD-1" style={{width:"1100px", maxWidth: "100%"}} /></div>
            <div><img src="/img/instructions/addDevice3.jpg" alt="instalationSD-1" style={{width:"1100px", maxWidth: "100%"}} /></div> 
        </AccordionDetails>
      </Accordion>
    
    </div>
  );
}

export default Instructions;