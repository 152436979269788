// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ако искате други елементи вдясно */
    padding: 10px 20px;
    background-color: #f8f9fa; /* Светъл фон, можете да го промените */
    position: fixed; /* Закрепване на хедъра в горната част */
    top: 0;
    left: 0;
    width: 100%; /* Заема цялата ширина на страницата */
    z-index: 1000; /* Над всички други елементи */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Лека сянка за естетика */
  }
  
  .header-logo {
    height: 40px; /* Регулирайте размера на логото */
    margin-right: 20px; /* Разстояние от другите елементи */
  }
  
  .header-title {
    font-size: 24px;
    font-weight: bold;
    color: #333; /* Тъмен текст, можете да го промените */
    margin: 0;
  }`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B,EAAE,qCAAqC;IACrE,kBAAkB;IAClB,yBAAyB,EAAE,uCAAuC;IAClE,eAAe,EAAE,wCAAwC;IACzD,MAAM;IACN,OAAO;IACP,WAAW,EAAE,sCAAsC;IACnD,aAAa,EAAE,8BAA8B;IAC7C,wCAAwC,EAAE,2BAA2B;EACvE;;EAEA;IACE,YAAY,EAAE,kCAAkC;IAChD,kBAAkB,EAAE,mCAAmC;EACzD;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,WAAW,EAAE,wCAAwC;IACrD,SAAS;EACX","sourcesContent":[".header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between; /* Ако искате други елементи вдясно */\n    padding: 10px 20px;\n    background-color: #f8f9fa; /* Светъл фон, можете да го промените */\n    position: fixed; /* Закрепване на хедъра в горната част */\n    top: 0;\n    left: 0;\n    width: 100%; /* Заема цялата ширина на страницата */\n    z-index: 1000; /* Над всички други елементи */\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Лека сянка за естетика */\n  }\n  \n  .header-logo {\n    height: 40px; /* Регулирайте размера на логото */\n    margin-right: 20px; /* Разстояние от другите елементи */\n  }\n  \n  .header-title {\n    font-size: 24px;\n    font-weight: bold;\n    color: #333; /* Тъмен текст, можете да го промените */\n    margin: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
