import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Button, Table, TableBody, TableCell, TableRow, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [posTypes, setPosTypes] = useState([]); // Добавяме състояние за POS Types
  const [placements, setPlacements] = useState([]); // Състояние за placements

  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    channels: [],
    tradeSegment: [],
    tier: [],
    screenType: [],
    placement: '',
    additionalPlacement: '',
    placementToExclude: '',
    posTypes: [], // Добавяме POS Types към формата
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  useEffect(() => {
    fetchCampaigns();
    fetchPosTypes(); // Извикваме POS Types при рендериране
    fetchPlacements(); // Зареждане на placements
  }, []);

  useEffect(() => {
    //   console.log('Current formData:', formData);
  }, [formData]);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('/api/campaigns');
      setCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const fetchPosTypes = async () => {
    try {
      const response = await axios.get('/api/pmiDevices/pos-types/unique'); // Вземаме уникални POS Types
      setPosTypes(response.data);
    } catch (error) {
      console.error('Error fetching POS Types:', error);
    }
  };

  const fetchPlacements = async () => {
    try {
      const response = await axios.get('/api/pmiDevices/placements/unique'); // Вземаме уникални placements
      setPlacements(response.data);
    } catch (error) {
      console.error('Error fetching placements:', error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (!formData.name || !formData.startDate || !formData.endDate) {
      alert('Name, Start Date, and End Date are required!');
      return;
    }
    try {
      if (selectedCampaign) {
        await axios.put(`/api/campaigns/${selectedCampaign._id}`, formData);
      } else {
        await axios.post('/api/campaigns/create', formData);
      }
      fetchCampaigns();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving campaign:', error);
    }
  };

  const handleEdit = (campaign) => {
    setSelectedCampaign(campaign);
    setFormData(campaign);
    setOpenDialog(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/campaigns/${id}`);
      fetchCampaigns();
    } catch (error) {
      console.error('Error deleting campaign:', error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCampaign(null);
    setFormData({
      name: '',
      startDate: '',
      endDate: '',
      channels: [],
      tradeSegment: [],
      tier: [],
      screenType: [],
      placement: '',
      additionalPlacement: '',
      placementToExclude: '',
      posTypes: [],
    });
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Campaigns Management
      </Typography>
      <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
        Add New Campaign
      </Button>
      <Table>
        <TableBody>
          {campaigns.map((campaign) => (
            <TableRow key={campaign._id}>
              <TableCell>{campaign.name}</TableCell>
              <TableCell>{campaign.description}</TableCell>
              <TableCell>{campaign.type}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" onClick={() => handleEdit(campaign)}>
                  Edit
                </Button>
                <Button variant="contained" color="secondary" onClick={() => handleDelete(campaign._id)} style={{ marginLeft: '10px' }}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedCampaign ? 'Edit Campaign' : 'Add New Campaign'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            value={formData.name || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Start Date"
            name="startDate"
            type="date"
            value={formData.startDate ? new Date(formData.startDate).toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            name="endDate"
            type="date"
            value={formData.endDate ? new Date(formData.endDate).toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* POS Types */}
          <Typography variant="h6" gutterBottom>POS Types</Typography>
          {posTypes.map((type) => (
            <div key={type}>
              <label>
                <input
                  type="checkbox"
                  checked={formData.posTypes?.includes(type) || false}
                  onChange={(e) => {
                    const updatedPosTypes = e.target.checked
                      ? [...(formData.posTypes || []), type]
                      : (formData.posTypes || []).filter((t) => t !== type);
                    setFormData({ ...formData, posTypes: updatedPosTypes });
                  }}
                />
                {type}
              </label>
            </div>
          ))}
          <TextField
            label="POS Types (comma separated)"
            name="posTypes"
            value={formData.posTypes ? formData.posTypes.join(', ') : ''}
            onChange={(e) =>
              setFormData({ ...formData, posTypes: e.target.value.split(',').map((pt) => pt.trim()) })
            }
            fullWidth
            margin="normal"
          />
          {/* Channels */}
          <Typography variant="h6" gutterBottom>Channels</Typography>
          {['IR', 'BR'].map((channel) => (
            <div key={channel}>
              <label>
                <input
                  type="checkbox"
                  checked={formData.channels?.includes(channel) || false} // Уверете се, че няма undefined
                  onChange={(e) => {
                    const updatedChannels = e.target.checked
                      ? [...(formData.channels || []), channel]
                      : (formData.channels || []).filter((ch) => ch !== channel);
                    setFormData({ ...formData, channels: updatedChannels });
                  }}
                />
                {channel}
              </label>
            </div>
          ))}

          {/* Tier */}
          <Typography variant="h6" gutterBottom>Tier</Typography>
          {[1, 2, 3, 4, 5].map((tier) => (
            <div key={tier}>
              <label>
                <input
                  type="checkbox"
                  checked={formData.tier?.includes(tier) || false}
                  onChange={(e) => {
                    const updatedTier = e.target.checked
                      ? [...(formData.tier || []), tier]
                      : (formData.tier || []).filter((t) => t !== tier);
                    setFormData({ ...formData, tier: updatedTier });
                  }}
                />
                {tier}
              </label>
            </div>
          ))}
          {/* Trade Segment */}
          <Typography variant="h6" gutterBottom>Trade Segment</Typography>
          {['General trade', 'KA'].map((segment) => (
            <div key={segment}>
              <label>
                <input
                  type="checkbox"
                  checked={formData.tradeSegment?.includes(segment) || false}
                  onChange={(e) => {
                    const updatedTradeSegment = e.target.checked
                      ? [...(formData.tradeSegment || []), segment]
                      : (formData.tradeSegment || []).filter((seg) => seg !== segment);
                    setFormData({ ...formData, tradeSegment: updatedTradeSegment });
                  }}
                />
                {segment}
              </label>
            </div>
          ))}
          <TextField
            label="Placement (comma separated)"
            name="placement"
            value={formData.placement ? formData.placement.join(', ') : ''}
            onChange={(e) =>
              setFormData({ ...formData, placement: e.target.value.split(',').map((pl) => pl.trim()) })
            }
            fullWidth
            margin="normal"
          />
          <Typography variant="h6" gutterBottom>Placement</Typography>
          {placements.map((placement) => (
            <div key={placement}>
              <label>
                <input
                  type="checkbox"
                  checked={formData.placement?.includes(placement) || false}
                  onChange={(e) => {
                    const updatedPlacements = e.target.checked
                      ? [...(formData.placement || []), placement]
                      : (formData.placement || []).filter((pl) => pl !== placement);
                    setFormData({ ...formData, placement: updatedPlacements });
                  }}
                />
                {placement}
              </label>
            </div>
          ))}
          <TextField
            label="Additional Placement (comma separated)"
            name="additionalPlacement"
            value={formData.additionalPlacement ? formData.additionalPlacement.join(', ') : ''}
            onChange={(e) =>
              setFormData({ ...formData, additionalPlacement: e.target.value.split(',').map((ap) => ap.trim()) })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Placement to Exclude (comma separated)"
            name="placementToExclude"
            value={formData.placementToExclude ? formData.placementToExclude.join(', ') : ''}
            onChange={(e) =>
              setFormData({ ...formData, placementToExclude: e.target.value.split(',').map((pte) => pte.trim()) })
            }
            fullWidth
            margin="normal"
          />
          {/* Screen Type */}
          <Typography variant="h6" gutterBottom>Screen Type</Typography>
          {['Regular screen', 'Selling screen', 'Small cash desk screens'].map((screen) => (
            <div key={screen}>
              <label>
                <input
                  type="checkbox"
                  checked={formData.screenType?.includes(screen) || false}
                  onChange={(e) => {
                    const updatedScreenType = e.target.checked
                      ? [...(formData.screenType || []), screen]
                      : (formData.screenType || []).filter((st) => st !== screen);
                    setFormData({ ...formData, screenType: updatedScreenType });
                  }}
                />
                {screen}
              </label>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Campaigns;