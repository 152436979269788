import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper } from '@mui/material';

const DevicesTable = ({ groupedDevices }) => {
  if (!groupedDevices || Object.keys(groupedDevices).length === 0) {
    return (
      <Typography variant="h6" gutterBottom>
        No devices available to display.
      </Typography>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" gutterBottom>
       
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>TAL Name</strong></TableCell>
            <TableCell><strong>City</strong></TableCell>
            <TableCell><strong>TA</strong></TableCell>
            <TableCell><strong>Device Count</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedDevices).map((tal) => (
            Object.entries(groupedDevices[tal]).flatMap(([city, tas]) =>
              Object.entries(tas).map(([ta, count], index) => (
                <TableRow key={`${tal}-${city}-${ta}`}>
                  <TableCell>{index === 0 ? tal : ''}</TableCell>
                  <TableCell>{city}</TableCell>
                  <TableCell>{ta}</TableCell>
                  <TableCell>{count}</TableCell>
                </TableRow>
              ))
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevicesTable;