import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

const AddableSelect = ({ label, value, options = [], onChange, onAddNew }) => {
    const [addingNew, setAddingNew] = useState(false);

    const handleAddNew = (newValue) => {
        if (newValue) {
            onAddNew(newValue); // Изпращаме новата стойност към родителя
            setAddingNew(false); // Изключваме режима за добавяне
        }
    };

    return (
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value || ''}
                onChange={(e) => {
                    if (e.target.value === 'add-new') {
                        setAddingNew(true);
                    } else {
                        onChange(e.target.value);
                    }
                }}
                fullWidth
                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                renderValue={(selected) => (selected ? selected : 'Select an option')}
            >
                {options.map((option) => (
                    <MenuItem key={`option-${option}`} value={option}>
                        {option}
                    </MenuItem>
                ))}
                <MenuItem value="add-new">Add new value</MenuItem>
            </Select>
            {addingNew && (
                <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Enter new value"
                    onBlur={(e) => {
                        const newValue = e.target.value.trim();
                        handleAddNew(newValue);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            const newValue = e.target.value.trim();
                            handleAddNew(newValue);
                        }
                    }}
                />
            )}
        </FormControl>
    );
};

export default AddableSelect;
