import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Dashboard from './pages/Dashboard';
import Campaigns from './pages/Campaigns';
import Devices from './pages/Devices';
import Contacts from './pages/Contacts';
import Login from './pages/Login';
import Register from './pages/Register';
import ProtectedRoute from './components/ProtectedRoute';
import ImportPage from './pages/ImportPage';
import UploadContent from './pages/UploadContent';


function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  return (
    <Router>
      <div className="app">
        {/* Хедър */}
        <Header />
        {/* Странично меню и съдържание */}
        <div style={{ display: 'flex' }}>
          <Sidebar />
          <div className="content">
            <Routes>
              <Route path="/login" element={<Login setToken={saveToken} />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/devices"
                element={
                  <ProtectedRoute token={token}>
                    <Devices />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute token={token}>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/upload-content"
                element={
                  <ProtectedRoute token={token}>
                    <UploadContent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/campaigns"
                element={
                  <ProtectedRoute token={token}>
                    <Campaigns />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contacts"
                element={
                  <ProtectedRoute token={token}>
                    <Contacts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/import"
                element={
                  <ProtectedRoute token={token}>
                    <ImportPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </div>
        {/* Футър */}
        <Footer />
      </div>
    </Router>
  );
}


export default App;