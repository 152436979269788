import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Typography } from '@mui/material';

const DeviceFilters = ({ filters = {}, onFilterChange }) => {
  const defaultFilters = {
    sn: '',
    activeMac: '',
    registered: false,
    appVersion: '',
    deviceRegistrationCode: '',
    lastSeen: '',
    createdAt: '',
    updatedAt: '',
  };

  const [localFilters, setLocalFilters] = useState({ ...defaultFilters, ...filters });

  useEffect(() => {
    setLocalFilters((prevFilters) => ({ ...defaultFilters, ...filters }));
  }, [filters]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: value || '' };
      onFilterChange(updatedFilters);
      return updatedFilters;
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setLocalFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: checked };
      onFilterChange(updatedFilters);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setLocalFilters(defaultFilters);
    onFilterChange(defaultFilters);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography variant="h6">Device Filters</Typography>
      <TextField label="Serial Number" name="sn" value={localFilters.sn ?? ''} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Active MAC Address" name="activeMac" value={localFilters.activeMac ?? ''} onChange={handleInputChange} fullWidth margin="normal" />
      <FormControlLabel
        control={<Checkbox checked={!!localFilters.registered} onChange={handleCheckboxChange} name="registered" />}
        label="Registered"
      />
      <TextField label="App Version" name="appVersion" value={localFilters.appVersion ?? ''} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Device Registration Code" name="deviceRegistrationCode" value={localFilters.deviceRegistrationCode ?? ''} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Last Seen" name="lastSeen" value={localFilters.lastSeen ?? ''} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Created At" name="createdAt" value={localFilters.createdAt ?? ''} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Updated At" name="updatedAt" value={localFilters.updatedAt ?? ''} onChange={handleInputChange} fullWidth margin="normal" />
      <div style={{ marginTop: '10px' }}>
        <Button variant="outlined" color="secondary" onClick={clearFilters}>
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default DeviceFilters;
