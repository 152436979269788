import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Button,
  Drawer,
  Box,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Checkbox,
  List,
  ListItem,
  Select,
  MenuItem,
  FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CampaignDynamicFields from './CampaignDynamicFields';
import VideoPreview from './VideoPreview';
import FileSelector from './FileSelector';
import UploadFile from './UploadFile';
import { listFiles } from '../services/api';
import { fetchCategories } from '../services/api'; // Импортиране на функцията за извличане на категории

const CampaignEditor = ({ open, onClose, initialData, onSave }) => {
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    category: '', // Поле за категория
    dynamicFields: {
      channels: [],
      tradeSegment: [],
      tire: [],
      screenType: [],
      placementNew: [],
      additionalPlacement: '',
      placementToExclude: '',
      posTypes: [],
      posTypeNew: [],
      kaName: [],
      manned: [],
    },
    files: [],
    manualActivation: false,
    isActive: true,
    ...initialData,
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileListRef = useRef(null);
  const [availableFiles, setAvailableFiles] = useState([]);
  const [campaignFiles, setCampaignFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // Състояние за търсене
  const [categories, setCategories] = useState([]); // Списък с категории

  // Филтрирани налични файлове
  const filteredAvailableFiles = availableFiles.filter(file =>
    file.originalFileName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    file.fileName?.toLowerCase().includes(searchTerm.toLowerCase())
  );


  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        keyVisual: initialData.keyVisual?._id || null, // Само `_id`
        landscape: initialData.landscape?._id || null,
        portrait: initialData.portrait?._id || null,
        files: initialData.files || [], // Уверете се, че файловете са налични
        dynamicFields: {
          channels: initialData.channels || [],
          tradeSegment: initialData.tradeSegment || [],
          tire: initialData.tire || [],
          screenType: initialData.screenType || [],
          placementNew: initialData.placementNew || [],
          manned: initialData.manned || [],
          additionalPlacement: initialData.additionalPlacement?.[0] || '',
          placementToExclude: initialData.placementToExclude?.[0] || '',
          posTypes: initialData.posTypes || [],
          posTypeNew: initialData.posTypeNew || [],
          kaName: initialData.kaName || [],
        },
        category: initialData.category?._id || '', // Извличане на `_id`
        manualActivation: initialData.manualActivation || false,
        isActive: initialData.isActive || false, // Уверяваме се, че `isActive` е зададено

      });
      setCampaignFiles(initialData.files || []);
    } else {
      console.log('No initial data');
      /*
      setFormData({
      ...formData,
      dynamicFields: {
        channels: [],
        tradeSegment: ['BRAND RETAIL', 'KEY ACCOUNT', 'GENERAL TRADE', 'HORECA'],
        posTypeNew: ['Partner', 'Reseller', 'HNB Handler', 'CC Handler', 'Boutique', 'Island', 'LAMP'],
        placementNew: ['Inside', 'Outside', 'Immersive zone'],
        manned: ['Manned', 'Unmanned'],
        screenType: ['Regular screen', 'Selling screen', 'Small cash desk screens'],
        kaName: ['AIA', 'Airport', 'EKO', 'FF', 'Lagardere', 'Lukoil', 'OMV', 'Partnere', 'Petrol'],
      },
    });
      */
    }
  }, [initialData]);

  // Извличане на категории при зареждане
  useEffect(() => {
    const loadCategories = async () => {
      try {
        const allCategories = await fetchCategories();
        const campaignCategories = allCategories.filter(
          (category) => category.type === 'Campaign'
        );
        setCategories(campaignCategories);
        console.log('Categories:', campaignCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    loadCategories();
  }, []);

  // Обработчик за избор на категория
  const handleCategoryChange = (event) => {
    setFormData({ ...formData, category: event.target.value });
  };

  //Извличане на файловете при зареждане
  useEffect(() => {
    const fetchAvailableFiles = async () => {
      try {
        const response = await listFiles();
        const files = Array.isArray(response) ? response : response.files || [];
        setAvailableFiles(files.filter((file) => !campaignFiles.some((f) => f._id === file._id)));
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchAvailableFiles();
  }, [campaignFiles]);

  const handleDynamicFieldsChange = (updatedFields) => {
    setFormData({
      ...formData,
      dynamicFields: updatedFields,
    });
  };

  const handleAddFile = (file) => {
    setCampaignFiles([...campaignFiles, file]);
    setAvailableFiles(availableFiles.filter((f) => f._id !== file._id));
  };

  const handleRemoveFile = (file) => {
    setAvailableFiles([...availableFiles, file]);
    setCampaignFiles(campaignFiles.filter((f) => f._id !== file._id));
  };

  const handleNewFileUpload = (newFiles) => {
    setSelectedFiles((prev) => [...prev, ...newFiles]);
    if (fileListRef.current) {
      console.log("Refreshing files...");
      fileListRef.current.refreshFiles(); // Обновяване на списъка с файлове
    }
  };

  const handleSave = async () => {
    console.log("save campaign")
    if (!formData.name || !formData.startDate || !formData.endDate || !formData.category) {
      alert('Name, Start Date, End Date, and Category are required!');
      return;
    }
    

    const payload = {
      ...formData,
      ...formData.dynamicFields,
      tradeSegment: formData.dynamicFields.tradeSegment.filter(value => value !== 'Unknown'),
      kaName: formData.dynamicFields.kaName.filter(value => value !== 'Unknown'),
      posTypeNew: formData.dynamicFields.posTypeNew.filter(value => value !== 'Unknown'),
      manned: formData.dynamicFields.manned.filter(value => value !== 'Unknown'),
      placementNew: formData.dynamicFields.placementNew.filter(value => value !== 'Unknown'),
      tire: formData.dynamicFields.tire.filter(value => value !== 'Unknown'),
      screenType: formData.dynamicFields.screenType.filter(value => value !== 'Unknown'),
      files: campaignFiles.map((file) => file._id),
      landscape: formData.landscape || null,
      portrait: formData.portrait || null,
      manualActivation: formData.manualActivation,
    };

    try {
      await onSave(payload);
      onClose();
    } catch (error) {
      console.error('Error saving campaign:', error);
    }
  };
  const isValidCategory = categories.some((cat) => cat._id === formData.category);

  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ sx: { width: 1200 } }}>

      {/*   <Box sx={{ width: 800, p: 3 }}>*/}
      <Typography variant="h5">{formData._id ? 'Edit Campaign' : 'Add Campaign'}</Typography>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">General Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            size="small"
            label="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            size="small"
            label="Start Date"
            type="date"
            value={formData.startDate ? new Date(formData.startDate).toISOString().split('T')[0] : ''}
            onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            size="small"
            label="End Date"
            type="date"
            value={formData.endDate ? new Date(formData.endDate).toISOString().split('T')[0] : ''}
            onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isActive}
                onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
              />
            }
            label="Is Active"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.manualActivation}
                onChange={(e) => setFormData({ ...formData, manualActivation: e.target.checked })}
              />
            }
            label="Manual Activation"
          />
          <Select
            label="Category"
            size="small"
            value={isValidCategory ? formData.category : ''} // Ако не е валидна, задаваме празен низ
            onChange={handleCategoryChange}
            fullWidth


          >
            {categories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Dynamic Fields</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CampaignDynamicFields
            dynamicFields={formData.dynamicFields}
            onChange={handleDynamicFieldsChange}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Files</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Key Visual</Typography>
              {formData.keyVisual ? (
                <VideoPreview video={campaignFiles.find((file) => file._id === formData.keyVisual)} />
              ) : (
                <Typography>No Key Visual</Typography>
              )}
              <FileSelector              
                files={campaignFiles}
                value={formData.keyVisual}
                onSelect={(fileId) => setFormData({ ...formData, keyVisual: fileId })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Landscape Video</Typography>
              {formData.landscape ? (
                <VideoPreview video={campaignFiles.find((file) => file._id === formData.landscape)} />
              ) : (
                <Typography>No Landscape Video</Typography>
              )}
              <FileSelector
                files={campaignFiles}
                value={formData.landscape}
                onSelect={(fileId) => setFormData({ ...formData, landscape: fileId })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Portrait Video</Typography>
              {formData.portrait ? (
                <VideoPreview video={campaignFiles.find((file) => file._id === formData.portrait)} />
              ) : (
                <Typography>No Portrait Video</Typography>
              )}
              <FileSelector                
                files={campaignFiles}
                value={formData.portrait}
                onSelect={(fileId) => setFormData({ ...formData, portrait: fileId })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Files in Campaign</Typography>
              <List>
                {campaignFiles.map((file) => (
                  <ListItem key={file._id} disablePadding={true}>
                    <Checkbox checked onClick={() => handleRemoveFile(file)} />
                    {file.originalFileName || file.fileName}
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Available Files </Typography>
              {/* Поле за търсене */}
              <TextField
                size="small" 
                label="Search Files"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '1rem' }}
              />
              <List style={{ maxHeight: '300px', overflow: "auto" }}>
                {filteredAvailableFiles.map((file) => (
                  <ListItem key={file._id} disablePadding={true}>
                    <Checkbox onClick={() => handleAddFile(file)} />
                    {file.originalFileName || file.fileName}
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <UploadFile onUploadComplete={handleNewFileUpload}
        context={{ campaignId: formData._id }} // Предаваме ID на текущата кампания
      />

      <Box sx={{ mt: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSave} sx={{ ml: 2 }}>
          Save
        </Button>
      </Box>
      {/*   </Box>*/}
    </Drawer>
  );
};

export default CampaignEditor;
