import React, { useState } from 'react';
import axios from 'axios';

// Преобразуване на Blob в base64
const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]); // Взема само base64 частта
        reader.onerror = (error) => reject(error);
    });

function UploadContent() {
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState({});

    const handleFileChange = (e) => {
        setFiles([...e.target.files]);
    };

    const uploadFile = async (file) => {
        const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        const fileId = file.name.split('.')[0];

        for (let i = 0; i < totalChunks; i++) {
            const chunk = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
            const base64Chunk = await toBase64(chunk);

            const formData = new FormData();
            formData.append('fileChunk', base64Chunk);
            formData.append('chunkIndex', i);
            formData.append('totalChunks', totalChunks);
            formData.append('fileId', fileId);
            formData.append('originalFileName', file.name);

            console.log('Uploading chunk:', {
                fileId,
                chunkIndex: i,
                totalChunks,
                originalFileName: file.name,
            });

            await axios.post('/api/content/upload-resumable', formData, {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress((prev) => ({ ...prev, [file.name]: percentCompleted }));
                },
            });
        }
        alert(`File ${file.name} uploaded successfully.`);
    };

    const handleUpload = async () => {
        for (const file of files) {
            await uploadFile(file);
        }
    };

    return (
        <div>
            <h2>Upload Content</h2>
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>
            <div>
                {files.map((file) => (
                    <div key={file.name}>
                        <p>{file.name}</p>
                        <progress value={progress[file.name] || 0} max="100" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UploadContent;