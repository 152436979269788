import React, { useState, useEffect } from "react";
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { getDevices, getInactiveDevices, getHourlyDeviceStats } from "../services/api"; // Функции за заявка към API
import { Card, CardContent, Typography, Grid } from "@mui/material";

const Dashboard = () => {
    const [activeDevices, setActiveDevices] = useState([]);
    const [inactiveDevices, setInactiveDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hourlyStats, setHourlyStats] = useState([]);

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                const active = await getDevices();
                const inactive = await getInactiveDevices();
                console.log('Active: ', active);
                console.log('Inactive: ', inactive.inactiveDevices);
                setActiveDevices(active.devices);
                setInactiveDevices(inactive.inactiveDevices || []); // API връща inactiveDevices като ключ
            } catch (error) {
                console.error("Error fetching devices:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchDevices();
    }, []);

    useEffect(() => {
        const fetchHourlyStats = async () => {
            try {
                const stats = await getHourlyDeviceStats();
                console.log("Hourly Stats:", stats);

                // Подготвяме празен обект за всеки час
                const formattedData = Array.from({ length: 24 }, (_, i) => ({
                    hour: `${i.toString().padStart(2, '0')}:00`, // 00:00 - 23:00
                    brokers: {}
                }));

                // Поправяме формата на hour и групираме по брокери
                stats.forEach(({ _id, count }) => {
                    console.log("Processing:", _id); // Проверяваме структурата на данните
                    const date = new Date(_id.hour);
                    const formattedHour = `${date.getUTCHours().toString().padStart(2, '0')}:00`;

                    const hourIndex = formattedData.findIndex(d => d.hour === formattedHour);
                    if (hourIndex !== -1) {
                        formattedData[hourIndex].brokers[_id.broker || "Unknown"] = count;
                    }
                });
                console.log("📊 Final formatted data:", formattedData);
                setHourlyStats(formattedData);
            } catch (error) {
                console.error("Error fetching hourly stats:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchHourlyStats();
    }, []);


    useEffect(() => {
        console.log("📊 Chart Data After Fetch:", hourlyStats);
    }, [hourlyStats]);

    const data = [
        { name: "Active", count: activeDevices.length },
        { name: "Inactive", count: inactiveDevices.length }
    ];
    console.log("DATA: ", data);
    return (
        <div style={{ padding: "20px" }}>
            <Typography variant="h4" gutterBottom>📊 Device Dashboard</Typography>

            {loading ? (
                <Typography>Loading data...</Typography>
            ) : (
                <Grid container spacing={3}>
                    {/* Карта с броя на активните устройства */}
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">🟢 Active Devices</Typography>
                                <Typography variant="h4">{activeDevices.length}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Карта с броя на неактивните устройства */}
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">🔴 Inactive Devices</Typography>
                                <Typography variant="h4">{inactiveDevices.length}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Графика */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">📈 Device Activity</Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={data}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Линия графика за активност по часове */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">⏳ Activity per Hour (Last 24h)</Typography>
                                <ResponsiveContainer width="100%" height={300}>
  <LineChart
    key={hourlyStats.length} // Принудително рендериране при промяна на данните
    width={600}
    height={300}
    data={hourlyStats}
    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="hour" />
    <YAxis />
    <Tooltip />
    {Object.keys(hourlyStats[0]?.brokers || {}).map((broker, index) => (
      <Line
        key={index}
        type="monotone"
        dataKey={(data) => data.brokers[broker] || 0}
        name={broker}
        stroke={index % 2 === 0 ? "#8884d8" : "#82ca9d"}
        strokeWidth={2}
        dot={{ r: 6 }}
      />
    ))}
  </LineChart>
</ResponsiveContainer>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            )}
        </div>

    );
};

export default Dashboard;
