import React, { useEffect, useState } from 'react';
import { getUserDevices } from '../services/api';
import { getPmiDevices, fetchUniqueValues, fetchUsers, addContact, updateDevice, createDevice } from '../services/api';
import { CheckCircle, Cancel } from '@mui/icons-material'; // Импортирайте иконите
import DeviceFilters from '../components/pmiDeviceFilters';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid,
    Button
} from '@mui/material';

import DeviceFormDialog from '../components/DeviceFormDialog';
import exportToExcel from '../utils/exportToExcel';

const UserDevicesTable = () => {
    const { id } = useParams(); // Извличане на ID от URL
    const navigate = useNavigate();
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogType, setDialogType] = useState('create'); // 'create' or 'edit'
    const [uniqueValues, setUniqueValues] = useState({});
    const [users, setUsers] = useState([]);
    const [filteredDevices, setFilteredDevices] = useState(devices);
    const [filters, setFilters] = useState({
        deviceName: '',
        isRegistered: '',
        tradeSegment: [],
        kaName: [],
        posType: [],
        placementNew: [],
        manned: [],
        tire: [],
        screenType: [],
        isActive: '',
        region: [],
        talActual: [],
        taActual: [],
        city: [],
        posName: '',
        search: '', // Глобално поле за търсене
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { devices } = await getUserDevices();
                setDevices(devices);
                console.log(devices);
                const fields = ['posTypeNew', 'city', 'region', 'tradeSegment', 'orientation', 'screenType', 'manned', 'outputResolution', 'connectivity', 'splitter', 'seasonality', 'placementNew', 'isActive', 'tire', 'kaName', 'talActual', 'taActual', 'playerModel'];
                const uniqueData = await Promise.all(fields.map((field) => fetchUniqueValues(field)));
                const uniqueObj = fields.reduce((acc, field, index) => {
                    acc[field] = uniqueData[index].data.map((item) => item._id);
                    return acc;
                }, {});
                setUniqueValues(uniqueObj);
                const userData = await fetchUsers();
                setUsers(userData);
            } catch (err) {
                console.error('Error fetching user devices:', err);
                setError('Failed to fetch user devices.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (devices.length > 0) {
            setFilteredDevices(devices);
        }
    }, [devices]); // Следи промените в `devices`

    useEffect(() => {
        handleFilterChange(filters);
    }, [filters, devices]); // Следи промени в filters или devices

    useEffect(() => {
        if (id) {
            // Намерете устройството по ID и отворете формата
            const deviceToEdit = devices.find((device) => device._id === id);
            if (deviceToEdit) {
                handleOpenDialog('edit', deviceToEdit);
            }
        }
    }, [id, devices]);

    const handleFilterChange = (filters) => {
        console.log('Applying filters:', filters);
        const filtered = devices.filter((device) => {
            const searchTerm = filters.search?.toLowerCase() || '';
            const matchesSearch =
            !searchTerm ||
            Object.values(device).some((value) =>
                value?.toString().toLowerCase().includes(searchTerm)
            );

            const matchesName =
                !filters.deviceName || device.name?.toLowerCase().includes(filters.deviceName.toLowerCase());
                const matchesPosName =
                !filters.posName || // Ако няма филтър за POS Name, всички съвпадат
                device.posName?.toLowerCase().includes(filters.posName.toLowerCase());

                const matchesRegistration =
                filters.isRegistered === '' ||
                (filters.isRegistered === 'true' && device.serialNumber) ||
                (filters.isRegistered === 'false' && !device.serialNumber);
            const matchesTradeSegment =
                filters.tradeSegment.length === 0 || filters.tradeSegment.includes(device.tradeSegment);

            const matchesKaName =
                filters.tradeSegment.includes("KEY ACCOUNT") && device.tradeSegment === "KEY ACCOUNT"
                    ? filters.kaName.length === 0 || filters.kaName.includes(device.kaName)
                    : true;

            const matchesPosType =
                filters.posType.length === 0 || filters.posType.includes(device.posTypeNew);
            const matchesplacementNew =
                filters.placementNew.length === 0 || filters.placementNew.includes(device.placementNew);
            const matchesmanned =
                filters.manned.length === 0 || filters.manned.includes(device.manned);
            const matchestire =
                filters.tire.length === 0 || filters.tire.includes(device.tire);
            const matchesscreenType =
                filters.screenType.length === 0 || filters.screenType.includes(device.screenType);
            const matchesisActive =
                filters.isActive.length === 0 || filters.isActive.includes(device.isActive === 1 ? 'Yes' : 'No');
            const matchesRegion =
                filters.region.length === 0 || filters.region.includes(device.region);
            const matchesTalActual =
                filters.talActual.length === 0 || filters.talActual.includes(device.talActual);
            const matchesTaActual =
                filters.taActual.length === 0 || filters.taActual.includes(device.taActual);
            const matchesCity =
                filters.city.length === 0 || filters.city.includes(device.city);

            return matchesName && matchesRegistration && matchesTradeSegment && matchesKaName && matchesPosType && matchesplacementNew && matchesmanned && matchestire && matchesscreenType && matchesisActive && matchesRegion && matchesTalActual && matchesTaActual && matchesCity && matchesPosName && matchesSearch;
        });
        //      console.log('Filtered devices:', filtered);
        setFilteredDevices(filtered);
    };

    const handleOpenDialog = (type, device = null) => {
        setDialogType(type);
        setSelectedDevice(device);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedDevice(null);
        console.log('Dialog closed, filtert:', filters);
        //    handleFilterChange(filters); // Приложете текущите филтри отново
    };

    const handleDeviceSave = async (device) => {
        try {
            if (dialogType === 'create') {
                const createdDevice = await createDevice(device);
                setDevices((prev) => [...prev, createdDevice.device]);
            } else if (dialogType === 'edit') {
                const updatedDevice = await updateDevice(device._id, device);
                setDevices((prev) =>
                    prev.map((d) => (d._id === updatedDevice._id ? updatedDevice : d))
                );
            }
            handleFilterChange(filters); // Приложете текущите филтри след обновяване

            handleDialogClose();
        } catch (err) {
            console.error('Error saving device:', err);
            alert('Failed to save device.');
        }
    };

    const exportFilteredDevices = () => {
        if (filteredDevices.length === 0) {
            alert('No devices to export!');
            return;
        }

        // Форматирайте данните за експорт
        const exportData = filteredDevices.map((device) => ({
            Name: {
                t: 's', // Тип string
                v: device.name || '',
                l: { Target: `https://on-labs.eu/my-devices/edit/${device._id}` }, // Линк към страницата за редакция
                s: { font: { color: { rgb: "0000FF" }, underline: true } }, // Стил: син текст с подчертаване

            },
            "POS Name": device.posName || '',
            "Trade Segment": device.tradeSegment || '',
            "KA Name": device.kaName || '',
            "POS Type": device.posTypeNew || '',
            Placement: device.placementNew || '',
            Manned: device.manned || '',
            Tire: device.tire || '',
            "Screen Type": device.screenType || '',
            "Orientation": device.orientation || '',
            "Display Size": device.diagonal || '',
            City: device.city || '',
            Region: device.region || '',
            Address: device.address || '',
            "Mac Address": device.macAddress || '',
            "Serial Number": device.serialNumber || '',
            "Is Registered": device.serialNumber ? 'Yes' : 'No',
            "TAL Actual": device.talActual || '',
            "TA Actual": device.taActual || '',
            "Connectivity": device.simCardSN || '',
            "lastSeen": device.lastSeen || '',
            "isActive": device.isActive || '',
        }));

        // Извикване на функцията за експорт
        exportToExcel(exportData, 'filtered_devices.xlsx', 'Filtered Devices', {
            addFilters: true,
        });
    };
    if (loading) return <Typography>Loading devices...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Grid container spacing={2} style={{ height: '100vh' }}>
            <Grid item xs={12} style={{ height: '100%', overflow: 'auto' }}>
                <DeviceFilters
                    filters={filters}
                    onFilterChange={(updatedFilters) => {
                        setFilters(updatedFilters); // Запазете текущите филтри
                        handleFilterChange(updatedFilters); // Приложете филтрите
                    }}
                />
                <TableContainer component={Paper}>
                    <Typography variant="h4" gutterBottom>
                        My Devices
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginBottom: '10px' }}>

                        <Button
                            variant="contained"
                            color="primary"
                            style={{ float: 'right' }}
                            onClick={() => handleOpenDialog('create')}
                        >
                            Add Device
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportFilteredDevices}
                        >
                            Export to Excel R
                        </Button>
                    </div>
                    <Typography variant="h6" gutterBottom>
                        Devices matching filters And: {filteredDevices.length}

                    </Typography>
                    <Table sx={{ minWidth: { xs: '750px', sm: '1300px' } }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '30px', maxWidth: '30px' }}><strong>Reg</strong></TableCell> {/* Новата колонка */}
                                <TableCell style={{ width: '45px', maxWidth: '45px' }} sx={{ display: { xs: 'none', sm: 'table-cell' } }}><strong>Active</strong></TableCell>
                                <TableCell style={{ width: '90px', maxWidth: '90px' }}><strong>Name</strong></TableCell>
                                <TableCell><strong>POS Name</strong></TableCell>

                                {/* <TableCell><strong>Manned</strong></TableCell>
                                <TableCell><strong>TAL</strong></TableCell>
                                <TableCell><strong>TA</strong></TableCell> */}
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}><strong>City</strong></TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}><strong>Address</strong></TableCell>
                                <TableCell><strong>Placement</strong></TableCell>
                                <TableCell><strong>Orientation</strong></TableCell>
                                <TableCell style={{ width: '40px', maxWidth: '40px' }}><strong>Size</strong></TableCell>
                                {/*<TableCell><strong>mac</strong></TableCell>
                                <TableCell><strong>Serial Number</strong></TableCell> */}
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}><strong>Sim Card</strong></TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}><strong>Trade Segment</strong></TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}><strong>KA name</strong></TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}><strong>POS Type</strong></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredDevices.map((device, index) => (
                                <TableRow
                                    key={device._id || `${device.name}-${index}`}
                                    onClick={() => handleOpenDialog('edit', device)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        {device.serialNumber ? (
                                            <CheckCircle style={{ color: 'green' }} />
                                        ) : (
                                            <Cancel style={{ color: 'red' }} />
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{device.isActive === 1 ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{device.name}</TableCell>
                                    <TableCell>{device.posName || 'N/A'}</TableCell>


                                    {/*<TableCell>{device.manned || 'N/A'}</TableCell>                                   
                                    <TableCell>{device.talActual || 'N/A'}</TableCell>
                                    <TableCell>{device.taActual || 'N/A'}</TableCell>*/}
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{device.city || 'N/A'}</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{device.address || 'N/A'}</TableCell>
                                    <TableCell>{device.placementNew || 'N/A'}</TableCell>
                                    <TableCell>{device.orientation || 'N/A'}</TableCell>
                                    <TableCell>{device.diagonal || 'N/A'}</TableCell>
                                    {/*<TableCell>{device.macAddress || 'N/A'}</TableCell>
                                   <TableCell>{device.serialNumber || 'N/A'}</TableCell> */}
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{device.simCardSN || 'N/A'}</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{device.tradeSegment || 'N/A'}</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{device.kaName || 'N/A'}</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{device.posTypeNew || 'N/A'}</TableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>
            <DeviceFormDialog
                open={openDialog}
                onClose={handleDialogClose}
                onSave={handleDeviceSave}
                device={dialogType === 'edit' ? selectedDevice : null}
                uniqueValues={uniqueValues}
                setUniqueValues={setUniqueValues}
                users={users}
                setUsers={setUsers} // Предаване на setUsers
                allowCustomInput={true}
            />
        </Grid>
    );
};

export default UserDevicesTable;
