import React, { useEffect, useState } from 'react';
import {
  getDevicesCount,
  fetchDeviceSummary,
  getActiveDevices,
  fetchTradeSegmentOptions,
  fetchKaNameOptions,
  fetchPosTypetOptions,
  fetchPlacementOptions,
  fetchMannedOptions,
  fetchTireOptions,
  fetchScreeTypeOptions,
} from '../services/api'; // Импортираме функция за броя устройства
import { Typography, Checkbox, Box, TextField } from '@mui/material';
import { FormGroup, FormControlLabel } from '@mui/material';
import {DynamicCheckboxGroup,GlobalSelectAll} from './DynamicCheckboxGroup';

const CampaignDynamicFields = ({ dynamicFields, onChange }) => {

  const handleFieldChange = (field, values) => {
    console.log(`Field changed: ${field}, Values:`, values); // Диагностичен лог
    const updatedFields = { ...dynamicFields, [field]: values };

  // Логика за изчистване на kaName, ако KEY ACCOUNT е премахнат
  if (field === "tradeSegment" && !values.includes("KEY ACCOUNT")) {
    updatedFields.kaName = [];
  }

  // Изпращане на промените към родителския компонент
  onChange(updatedFields);
  };

  const [deviceCount, setDeviceCount] = useState(0);
  const [tradeSegmentOptions, setTradeSegmentOptions] = useState([]);
  const [posTypeOptions, setPosTypeOptions] = useState([]);
  const [kaNameOptions, setKaNameOptions] = useState([]);
  const [placementOptions, setPlacementOptions] = useState([]);
  const [mannedOptions, setMannedOptions] = useState([]);
  const [tireOptions, setTireOptions] = useState([]);
  const [screenTypeOptions, setScreenTypeOptions] = useState([]);
  const [deviceSummary, setDeviceSummary] = useState(null);
  const [devices, setDevices] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    tradeSegment: [],
    posType: [],
    kaName: [],
    placementNew: [],
    manned: [],
    tire: [],
    screenType: [],
  });

  const [availableFilters, setAvailableFilters] = useState({
    tradeSegment: [],
    posType: [],
    kaName: [],
    placementNew: [],
    manned: [],
    tire: [],
    screenType: [],
  });

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const allDevices = await getActiveDevices();
        setDevices(allDevices); // Добавете useState за devices
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, []);

  const fetchOptions = async (field, fetchFunction) => {
    try {
      const response = await fetchFunction();
      const transformedOptions = Array.isArray(response.data)
        ? response.data
          .filter(option => option._id !== null) // Премахваме null стойности
          .map(option => ({
            _id: option._id,
            count: option.count || 0,
          }))
        : [];

      const total = transformedOptions.reduce((sum, option) => sum + option.count, 0);

      setOptions(prevOptions => ({
        ...prevOptions,
        [field]: { total, data: transformedOptions },
      }));
    } catch (error) {
      console.error(`Error fetching ${field} options:`, error);
    }
  };


  useEffect(() => {
    const loadOptions = async () => {
      await Promise.all([
        fetchOptions('tradeSegment', fetchTradeSegmentOptions),
        fetchOptions('kaName', fetchKaNameOptions),
        fetchOptions('posTypeNew', fetchPosTypetOptions),
        fetchOptions('placementNew', fetchPlacementOptions),
        fetchOptions('manned', fetchMannedOptions),
        fetchOptions('tire', fetchTireOptions),
        fetchOptions('screenType', fetchScreeTypeOptions),
      ]);
    };

    loadOptions();
  }, []);


  const [options, setOptions] = useState({
    tradeSegment: { total: 0, data: [] },
    kaName: { total: 0, data: [] },
    posTypeNew: { total: 0, data: [] },
    placementNew: { total: 0, data: [] },
    manned: { total: 0, data: [] },
    tire: { total: 0, data: [] },
    screenType: { total: 0, data: [] },
  });

/*
  useEffect(() => {
    const loadTradeSegmentOptions = async () => {
      try {
        const response = await fetchTradeSegmentOptions(); // Вземете от бекенда
        const transformedOptions = Array.isArray(response.data)
          ? response.data.map((option, index) => ({
            _id: option._id || `unknown-${index}`, // Уникален ключ за null стойности
            count: option.count || 0, // Добавяне на броя
          }))
          : [];
        setTradeSegmentOptions(transformedOptions);
      } catch (error) {
        console.error('Error fetching Trade Segment options:', error);
      }
    };

    loadTradeSegmentOptions();
  }, []);

  useEffect(() => {
    const loadPosTypeOptions = async () => {
      try {
        const response = await fetchPosTypetOptions();
        const transformedOptions = Array.isArray(response.data)
          ? response.data.map((option, index) => ({
            _id: option._id || `unknown-${index}`, // Уникален ключ
            count: option.count || 0,
          }))
          : [];
        setPosTypeOptions(transformedOptions);
      } catch (error) {
        console.error('Error fetching Pos Type options:', error);
      }
    };

    loadPosTypeOptions();
  }, []);

  useEffect(() => {
    const loadKaNameOptions = async () => {
      try {
        const response = await fetchKaNameOptions();
        console.log('KA Name options response:', response); // Debugging

        const transformedOptions = Array.isArray(response.data)
          ? response.data.map(option => ({
            _id: option._id || 'Unknown', // Преобразуваме `null` в "Unknown"
            count: option.count || 0, // Уверяваме се, че `count` е валиден
          }))
          : [];
        console.log('Transformed KA Name options:', transformedOptions);
        setKaNameOptions({
          total: response.total || 0, // Вземаме `total` от отговора или задаваме 0
          data: transformedOptions, // Задаваме преобразуваните опции
        });
      } catch (error) {
        console.error('Error fetching KA Name options:', error);
      }
    };

    loadKaNameOptions();
  }, []);


  useEffect(() => {
    const loadPlacementOptions = async () => {
      try {
        const options = await fetchPlacementOptions();
        console.log('Placement options:', options);
        setPlacementOptions(options);
      } catch (error) {
        console.error('Error fetching placement options:', error);
      }
    };

    loadPlacementOptions();
  }, []);

  useEffect(() => {
    const loadMannedOptions = async () => {
      try {
        const response = await fetchMannedOptions(); // API заявка
        setMannedOptions({
          total: response.total, // Задаване на total от отговора
          data: response.data.map(({ _id, count }) => ({
            _id: _id === null ? 'unManned' : _id, // Преобразуване на null към 'unManned'
            count,
          })),
        });
      } catch (error) {
        console.error('Error fetching manned options:', error);
      }
    };

    loadMannedOptions();
  }, []);


  useEffect(() => {
    const loadTireOptions = async () => {
      try {
        const options = await fetchTireOptions();
        setTireOptions(options); // Примерен отговор: [{ _id: 1, count: 25 }, { _id: 2, count: 40 }]
      } catch (error) {
        console.error('Error fetching tire options:', error);
      }
    };
    loadTireOptions();
  }, []);

  useEffect(() => {
    const loadScreenTypeOptions = async () => {
      try {
        const options = await fetchScreeTypeOptions();
        setScreenTypeOptions(options);
      } catch (error) {
        console.error('Error fetching placement options:', error);
      }
    };

    loadScreenTypeOptions();
  }, []);
*/
  useEffect(() => {
    const fetchDeviceCount = async () => {
      try {
        const count = await getDevicesCount(dynamicFields); // Извличане на броя устройства
        setDeviceCount(count);
      } catch (error) {
        console.error('Error fetching device count:', error);
      }
    };

    fetchDeviceCount(); // Извикване при промяна на dynamicFields
  }, [dynamicFields]);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const summary = await fetchDeviceSummary(dynamicFields);
        console.log('Summary:', summary);
        setDeviceSummary(summary);
      } catch (error) {
        console.error('Error fetching device summary:', error);
      }
    };

    if (dynamicFields) {
      fetchSummary();
    }
  }, [dynamicFields]);

  useEffect(() => {
    const filteredOptions = devices.filter((device) =>
      selectedFilters.tradeSegment.includes(device.tradeSegment)
    );
    setAvailableFilters({
      ...availableFilters,
      placementNew: [...new Set(filteredOptions.map((d) => d.placementNew))],
      posType: [...new Set(filteredOptions.map((d) => d.posType))],
    });
  }, [selectedFilters]);

  const handleFilterChange = (key, value) => {
    const updatedFilters = {
      ...selectedFilters,
      [key]: value,
    };
    setSelectedFilters(updatedFilters);
    onChange(updatedFilters); // Notify the parent about changes
  };

  const renderSummaryWithTotal = (title, options) => (
    <div>
      <Typography variant="h6" gutterBottom>
        {title} (Total: {options?.total || 0})
      </Typography>
      {options?.data?.length ? (
        options.data.map(({ _id, count }) => (
          <label key={_id}>
            <Checkbox
              checked={dynamicFields[title]?.includes(_id)}
              onChange={(e) => handleCheckboxChange(title, _id)}
            />
            {_id || 'Unknown'} ({count})
          </label>
        ))
      ) : (
        <Typography>No options available</Typography>
      )}
    </div>
  );


  const handleCheckboxChange = (field, value) => {
    const updatedFields = {
      ...dynamicFields,
      [field]: Array.isArray(value)
        ? value // Ако value е масив, директно го използва
        : dynamicFields[field].includes(value)
          ? dynamicFields[field].filter((item) => item !== value) // Премахва стойността
          : [...dynamicFields[field], value], // Добавя стойността
    };

    // Преобразуване на "unManned" в null за бекенда
    if (field === 'manned') {
      updatedFields[field] = updatedFields[field].map((item) =>
        item === 'unManned' ? null : item
      );
    }
    // Ако "KEY ACCOUNT" е премахнат, изчистваме `kaName`
    if (field === "tradeSegment" && !updatedFields.tradeSegment.includes("KEY ACCOUNT")) {
      updatedFields.kaName = [];
    }
    console.log(`Updated field ${field}:`, updatedFields[field]);
    onChange(updatedFields);
  };


  const handleTextFieldChange = (field, value) => {
    onChange({
      ...dynamicFields,
      [field]: value,
    });
  };
  const isKeyAccountSelected = dynamicFields.tradeSegment.includes("KEY ACCOUNT");
  {/* console.log("Trade Segment:", dynamicFields.tradeSegment);
  console.log("Is KEY ACCOUNT Selected:", isKeyAccountSelected);*/}

  return (

    <div>
      {/* <Typography variant="h6">Dynamic Fields</Typography>*/}
      <div>Devices matching criteria: {deviceCount}</div>
      {/*
      {deviceSummary && (
        <Box sx={{ mt: 2 }}>
          {renderSummaryWithTotal('tradeSegment', deviceSummary.tradeSegment)}
          {renderSummaryWithTotal('posType', deviceSummary.posType)}
          {renderSummaryWithTotal('kaName', deviceSummary.kaName)}
          {renderSummaryWithTotal('placementNew', deviceSummary.placementNew)}
          {renderSummaryWithTotal('manned', deviceSummary.manned)}
          {renderSummaryWithTotal('screenType', deviceSummary.screenType)}
          {renderSummaryWithTotal('tire', deviceSummary.tire)}
        </Box>
      )}

      <FormGroup>
        {availableFilters.tradeSegment.map((segment) => (
          <FormControlLabel
            key={segment}
            control={
              <Checkbox
                checked={selectedFilters.tradeSegment.includes(segment)}
                onChange={() => handleFilterChange('tradeSegment', segment)}
              />
            }
            label={segment}
          />
        ))}
      </FormGroup>

      <Typography variant="h6" gutterBottom>Trade Segment</Typography>
      {['BRAND RETAIL', 'KEY ACCOUNT', 'GENERAL TRADE', 'HORECA'].map((segment) => (
        <label key={segment}>
          <Checkbox
            checked={dynamicFields.tradeSegment.includes(segment)}
            onChange={(e) => handleCheckboxChange('tradeSegment', segment)}
          />
          {segment}
        </label>
      ))}
*/}
<Typography variant="h5">Dynamic Fields</Typography>

<GlobalSelectAll
  groups={[
    { field: 'tradeSegment', options: options.tradeSegment.data, selectedValues: dynamicFields.tradeSegment },
    { field: 'kaName', options: options.kaName.data, selectedValues: dynamicFields.kaName },
    { field: 'posTypeNew', options: options.posTypeNew.data, selectedValues: dynamicFields.posTypeNew },
    { field: 'placementNew', options: options.placementNew.data, selectedValues: dynamicFields.placementNew },
    { field: 'manned', options: options.manned.data, selectedValues: dynamicFields.manned },
    { field: 'tire', options: options.tire.data, selectedValues: dynamicFields.tire },
    { field: 'screenType', options: options.screenType.data, selectedValues: dynamicFields.screenType },
  ]}
  onChange={(field, values) => {
    const updatedFields = { ...dynamicFields, [field]: values };
    onChange(updatedFields);

  }}
/>


      <DynamicCheckboxGroup
        title={`Trade Segment (Total: ${options.tradeSegment?.total || 0})`}
        field="tradeSegment"
        options={options.tradeSegment.data || []} // Проверете дали data е дефинирано
        selectedValues={dynamicFields.tradeSegment || []} // Уверете се, че е масив
       // onChange={(field, values) => onChange({ ...dynamicFields, [field]: values })} 
       onChange={handleFieldChange} // Свързване с обработката на промени
 
       showCounts={true}
      />

      {/*}
      <Typography variant="h6" gutterBottom>Pos Type</Typography>
      {['Partner', 'Reseller', 'HNB Handler', 'CC Handler', 'Boutique', 'Island', 'LAMP'].map((posTypeNew) => (
        <label key={posTypeNew}>
          <Checkbox
            checked={dynamicFields.posTypeNew.includes(posTypeNew)}
            onChange={(e) => handleCheckboxChange('posTypeNew', posTypeNew)}
          />
          {posTypeNew}
        </label>
      ))}
*/}
      {!isKeyAccountSelected && (
        <Typography variant="subtitle1" color="textSecondary">
          Please select "KEY ACCOUNT" in Trade Segment to enable this section.
        </Typography>
      )}
      <DynamicCheckboxGroup
        title={`KA Name (Total: ${options.kaName?.total || 0})`}
        field="kaName"
        options={options.kaName.data || []} // Подаване на data или празен масив
        selectedValues={dynamicFields.kaName || []} // Проверка за масив
        //onChange={(field, values) => onChange({ ...dynamicFields, [field]: values })}
       
        onChange={handleFieldChange} // Свързване с обработката на промени

        showCounts={true}
        disabled={!dynamicFields.tradeSegment.includes("KEY ACCOUNT")}

      />

      <DynamicCheckboxGroup
        title={`Pos Type (Total: ${options.posTypeNew?.total || 0})`}
        field="posTypeNew"
        options={options.posTypeNew.data || []} // Преобразувани данни
        selectedValues={dynamicFields.posTypeNew || []}
        onChange={(field, values) => onChange({ ...dynamicFields, [field]: values })}
        showCounts={true}
      />

      {/*}
      <Typography variant="h6" gutterBottom>KA Name</Typography>
      {['AIA', 'Airport', 'EKO', 'FF', 'Lagardere', 'Lukoil', 'OMV', 'Partnere', 'Petrol'].map((kaName) => (
        <label key={kaName}>
          <Checkbox
            checked={dynamicFields.kaName.includes(kaName)}
            onChange={(e) => handleCheckboxChange('kaName', kaName)}
          />
          {kaName}
        </label>
      ))}
*/}

      {/*}
      <Typography variant="h6" gutterBottom>
        PlacementNew (Total: {placementOptions?.total || 0})
      </Typography>
      {placementOptions?.data?.length ? (
        placementOptions.data.map(({ _id, count }) => (
          <label key={_id}>
            <Checkbox
              checked={dynamicFields.placementNew.includes(_id)}
              onChange={(e) => handleCheckboxChange('placementNew', _id)}
            />
            {_id || 'Unknown'} ({count})
          </label>
        ))
      ) : (
        <Typography>No options available</Typography>
      )}
*/}
      <DynamicCheckboxGroup
        title={`PlacementNew (Total: ${options.placementNew?.total || 0})`}
        field="placementNew"
        options={options.placementNew?.data || []} // Предаваме опции като масив
        selectedValues={dynamicFields.placementNew || []} // Проверка за масив
        onChange={(field, values) => onChange({ ...dynamicFields, [field]: values })}
        showCounts={true} // Показваме броя (count)
      />

      <DynamicCheckboxGroup
        title={`Manned (Total: ${options.manned?.total || 0})`}
        field="manned"
        options={options.manned?.data || []} // Предаваме опции като масив
        selectedValues={dynamicFields.manned || []} // Проверка за масив
        onChange={(field, values) => onChange({ ...dynamicFields, [field]: values })}
        showCounts={true} // Показваме броя (count)
      />

      <DynamicCheckboxGroup
        title={`Tier (Total: ${options.tire?.total || 0})`}
        field="tire"
        options={options.tire?.data || []} // Предаваме опции като масив
        selectedValues={dynamicFields.tire || []} // Проверка за масив
        onChange={(field, values) => onChange({ ...dynamicFields, [field]: values })}
        showCounts={true} // Показваме броя (count)
      />
      <DynamicCheckboxGroup
        title={`screenType (Total: ${options.screenType?.total || 0})`}
        field="screenType"
        options={options.screenType?.data || []} // Предаваме опции като масив
        selectedValues={dynamicFields.screenType || []} // Проверка за масив
        onChange={(field, values) => onChange({ ...dynamicFields, [field]: values })}
        showCounts={true} // Показваме броя (count)
      />
      {/*}
      <Typography variant="h6" gutterBottom>
        Manned (Total: {mannedOptions?.total || 0})
      </Typography>
      {mannedOptions?.data?.length ? (
        mannedOptions.data.map(({ _id, count }) => (
          <label key={_id}>
            <Checkbox
              checked={dynamicFields.manned.includes(_id)}
              onChange={() => handleCheckboxChange('manned', _id)}
            />
            {_id || 'Unknown'} ({count})
          </label>
        ))
      ) : (
        <Typography>No options available</Typography>
      )}

      <Typography variant="h6" gutterBottom>
        Tire (Total: {tireOptions?.total || 0})
      </Typography>
      {tireOptions?.data?.length ? (
        tireOptions.data.map(({ _id, count }) => (
          <label key={_id}>
            <Checkbox
              checked={dynamicFields.tire.includes(_id)}
              onChange={(e) => handleCheckboxChange('tire', _id)}
            />
            {_id || 'Unknown'} ({count})
          </label>
        ))
      ) : (
        <Typography>No options available</Typography>
      )}


      <Typography variant="h6" gutterBottom>
        Screen Type (Total: {screenTypeOptions?.total || 0})
      </Typography>
      {screenTypeOptions?.data?.length ? (
        screenTypeOptions.data.map(({ _id, count }) => (
          <label key={_id}>
            <Checkbox
              checked={dynamicFields.screenType.includes(_id)}
              onChange={(e) => handleCheckboxChange('screenType', _id)}
            />
            {_id || 'Unknown'} ({count})
          </label>
        ))
      ) : (
        <Typography>No options available</Typography>
      )}

*/}

      {/* 
      <TextField
        label="Additional Placement (comma separated)"
        value={dynamicFields.additionalPlacement}
        onChange={(e) => handleTextFieldChange('additionalPlacement', e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Placement to Exclude (comma separated)"
        value={dynamicFields.placementToExclude}
        onChange={(e) => handleTextFieldChange('placementToExclude', e.target.value)}
        fullWidth
        margin="normal"
      />
       */}
    </div>


  );
};

export default CampaignDynamicFields;