import React, { useState, useEffect } from 'react';
import { MenuItem, Select, InputLabel, FormControl, Checkbox, ListItemText, Typography, Button, TextField } from '@mui/material';
import {
    fetchTradeSegmentOptions,
    fetchKaNameOptions,
    fetchPosTypetOptions,
    fetchPlacementOptions,
    fetchMannedOptions,
    fetchTireOptions,
    fetchScreeTypeOptions,
    fetchisActiveOptions,
    fetchRegionOptions,
    fetchTalActualOptions,
    fetchTaActualOptions,
    fetchCityOptions,
    fetchSeasonality,
} from '../services/api';

import './DeviceFilters.css';
//import { set } from 'mongoose';


const DeviceFilters = ({ filters, onFilterChange }) => {
    const [tradeSegmentOptions, setTradeSegmentOptions] = useState([]);
    const [kaNameOptions, setKaNameOptions] = useState([]);
    const [posTypeOptions, setPosTypeOptions] = useState([]);
    const [placementOptions, setPlacementOptions] = useState([]);
    const [mannedOptions, setMannedOptions] = useState([]);
    const [tireOptions, setTireOptions] = useState([]);
    const [screenTypeOptions, setScreenTypeOptions] = useState([]);
    const [isActiveOptions, setisActiveTypeOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [talActualOptions, setTalActualOptions] = useState([]);
    const [taActualOptions, setTaActialOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [seasonalityOptions, setSeasonalityOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [localFilters, setLocalFilters] = useState(filters);
    const [searchTerms, setSearchTerms] = useState({});

    /*
      const [localFilters, setLocalFilters] = useState({
          ...filters,
          isActive: ['Yes'], // По подразбиране задаваме "Yes" за isActive
      });
      */
    /*
     const [filters, setFilters] = useState({
         tradeSegment: [],
         kaName: [],
         posType: [],
         placementNew: [],
         manned: [],
         tire: [],
         screenType: [],
         deviceName: '',
         isRegistered: '',
         isActive: ''
     });
     */
    const [isKaNameActive, setIsKaNameActive] = useState(false);

    useEffect(() => {
        // Зареждане на опции за филтрите
        const loadOptions = async () => {
            try {
                const [tradeSegmentData, kaNameData, posTypeData, placementData, mannedData, tireData, screenTypeData, isActiveData, regionData, talActualData, taActualData, cityData, seasonalityData] = await Promise.all([
                    fetchTradeSegmentOptions(),
                    fetchKaNameOptions(),
                    fetchPosTypetOptions(),
                    fetchPlacementOptions(),
                    fetchMannedOptions(),
                    fetchTireOptions(),
                    fetchScreeTypeOptions(),
                    fetchisActiveOptions(),
                    fetchRegionOptions(),
                    fetchTalActualOptions(),
                    fetchTaActualOptions(),
                    fetchCityOptions(),
                    fetchSeasonality(),
                ]);
                setTradeSegmentOptions(tradeSegmentData.data.map(option => option._id || 'Unknown'));
                setKaNameOptions(kaNameData.data.map(option => option._id || 'Unknown'));
                setPosTypeOptions(posTypeData.data.map(option => option._id || 'Unknown'));
                setPlacementOptions(placementData.data.map(option => option._id || 'Unknown'));
                setMannedOptions(mannedData.data.map(option => option._id || 'Unknown'));
                setTireOptions(tireData.data.map(option => option._id || 'Unknown'));
                setScreenTypeOptions(screenTypeData.data.map(option => option._id || 'Unknown'));
                setRegionOptions(regionData.data.map(option => option._id || 'Unknown'));
                setTalActualOptions(talActualData.data.map(option => option._id || 'Unknown'));
                setTaActialOptions(taActualData.data.map(option => option._id || 'Unknown'));
                setisActiveTypeOptions([...new Set(isActiveData.data.map(option => option._id))]);
                setCityOptions(cityData.data.map(option => option._id || 'Unknown'));                
                setSeasonalityOptions(seasonalityData.data.map(option => option._id || 'Unknown'));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };
        loadOptions();

    }, []);

    useEffect(() => {
        console.log('Is KA Name Active updated:', isKaNameActive);
    }, [isKaNameActive]);

    useEffect(() => { 
        console.log('Filters updated:', filters);
    }, [filters]);

    useEffect(() => {
        setLocalFilters(filters); // Обновете локалното състояние
    }, [filters]);

    /*
    //Добавяме за да може по подразбиране да се показва Yes isActive
    useEffect(() => {
        setLocalFilters((prevFilters) => ({
            ...prevFilters,
            isActive: prevFilters.isActive && prevFilters.isActive.length > 0 ? prevFilters.isActive : ['Yes'], // Ако няма стойност, задаваме "Yes"
        }));
    }, [filters]);
*/
    useEffect(() => {
        console.log('Filters applied:', localFilters);
        onFilterChange(localFilters); // Уверете се, че филтрите се прилагат при промяна
    }, [localFilters]);

    const handleMultiSelectChange = (event, field) => {
        const { value } = event.target;


        const updatedValue = field === 'isActive'
            ? value // Запазваме "Yes" или "No", без да преобразуваме обратно в 1/0
            : value;

        const updatedFilters = { ...filters, [field]: updatedValue };
        /*
       //Променихме за да бъде Yes is Active по посразбиране
        const updatedValue = field === 'isActive' ? value : value;
        const updatedFilters = { ...localFilters, [field]: updatedValue };  
 */
        // Проверка за KEY ACCOUNT в tradeSegment
        if (field === 'tradeSegment') {
            const isActive = value.includes("KEY ACCOUNT");
            setIsKaNameActive(isActive);
            if (!isActive) {
                updatedFilters.kaName = []; // Нулиране на KA Name
            }
        }

        setLocalFilters(updatedFilters);
        onFilterChange(updatedFilters);

        console.log('Field:', field, 'Value:', value);
        console.log('Updated filters:', updatedFilters);

    };

    const renderMultiSelect = (label, options, field) => {
        

        if (!options || options.length === 0) {
            return <Typography variant="body2">{label} options are not available.</Typography>;
        }
       
        //const isAllSelected = filters[field].length === options.length;

        const selectedValues = Array.isArray(filters[field]) ? filters[field] : [];
        const isAllSelected = selectedValues.length === options.length;

        const handleAllToggle = () => {
            const newValue = isAllSelected ? [] : options; // Ако е избрано "All", изберете всички, иначе изчистете
            setLocalFilters({ ...filters, [field]: newValue });
            onFilterChange({ ...filters, [field]: newValue });
        };
        // 🔹 Филтрираме опциите според търсенето
    // 🔹 Проверяваме дали `option` е string, иначе използваме `option.name`
    const filteredOptions = options.filter(option => {
        if (!option) return false; // 🔹 Пропускаме `undefined` или `null`
        const value = typeof option === "string" ? option : option?.name;
        return value?.toLowerCase().includes(searchTerms[field]?.toLowerCase() || "");
    });
        return (
            <FormControl style={{ margin: '5px', width: '160px', }} size="small">
                <InputLabel>{label}</InputLabel>

                <Select
                    multiple
                    //value={filters[field] || []}
                    value={selectedValues}
                    onChange={(e) => handleMultiSelectChange(e, field)}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {/* 🔹 Поле за търсене вътре в селекта */}
                <MenuItem >
                    <TextField
                        placeholder="Search..."
                        value={searchTerms[field] || ""}
                        onChange={(e) => setSearchTerms(prev => ({ ...prev, [field]: e.target.value }))}
                        fullWidth
                        size="small"
                    />
                </MenuItem>
                    {/* "All" опция */}
                    <MenuItem value="all">
                        <Checkbox
                            checked={isAllSelected}
                            onChange={handleAllToggle}
                        />
                        <ListItemText primary="All" />
                    </MenuItem>
                    {filteredOptions.map((option, idx) => (
                        <MenuItem key={idx} value={option}>
                           {/* <Checkbox checked={filters[field].includes(option)} />*/}
                           <Checkbox checked={selectedValues.includes(option)} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const clearAllFilters = () => {
        const clearedFilters = {
            tradeSegment: [],
            kaName: [],
            posType: [],
            placementNew: [],
            manned: [],
            tire: [],
            screenType: [],
            deviceName: '',
            isRegistered: '',
            isActive: [],
            region: [],
            talActual: [],
            taActual: [],
            city: [],
            seasonality: []
        };

        setLocalFilters(clearedFilters);
        onFilterChange(clearedFilters);

        console.log('Filters cleared:', clearedFilters); // Диагностика
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        const updatedFilters = { ...localFilters, [name]: value }; // Поддържа стойността като низ
        setLocalFilters(updatedFilters);
        //   setLocalFilters(updatedFilters);
        onFilterChange(updatedFilters);

        console.log('Updated Filters:', updatedFilters); // Диагностика
    };


    return (
        <div className="device-filters">
            <h3>Filter Devices</h3>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={clearAllFilters}
                >
                    Clear All Filters
                </Button>
            </div>
            <div>
                <label>Global Search:</label>
                <TextField
                    placeholder="Search across all fields"
                    name="search"
                    value={localFilters.search || ''}
                    onChange={(e) => handleFilterChange(e)} // Актуализира филтрите
                    size="small"
                />
                <label> Reev Code:</label>
                <TextField
                    placeholder="Search by code"
                    name="deviceName"
                    value={localFilters.deviceName || ''}
                    onChange={(e) => handleFilterChange(e)}
                    size="small"
                />
                <label>POS Name:</label>
                <TextField
                    placeholder="Search by POS Name"
                    name="posName" // Името на полето, което ще се използва за филтриране
                    value={localFilters.posName || ''} // Свързваме стойността с localFilters
                    onChange={(e) => handleFilterChange(e)} // Актуализираме филтъра при промяна
                    size="small"
                />
            </div>

            <div>
                <label>Registration Status:</label>
                <select
                    name="isRegistered"
                    value={localFilters.isRegistered}
                    onChange={(e) => handleFilterChange(e)}
                >
                    <option value="">All</option>
                    <option value="true">Registered</option>
                    <option value="false">Not Registered</option>
                </select>
            </div>
{/*
            
            {renderMultiSelect('Trade Segment', tradeSegmentOptions, 'tradeSegment')}
           
            {isKaNameActive
                ? renderMultiSelect('KA Name', kaNameOptions, 'kaName')
                : <Typography variant="body2">Select "KEY ACCOUNT" in Trade Segment to enable KA Name.</Typography>
            }
            {renderMultiSelect('POS Type', posTypeOptions, 'posType')}
            {renderMultiSelect('Placement', placementOptions, 'placementNew')}
            {renderMultiSelect('Manned', mannedOptions, 'manned')}
            {renderMultiSelect('Tier', tireOptions, 'tire')}
            {renderMultiSelect('Screen Type', screenTypeOptions, 'screenType')}
            {renderMultiSelect('Region', regionOptions, 'region')}
            {renderMultiSelect('TAL', talActualOptions, 'talActual')}
            {renderMultiSelect('TA', taActualOptions, 'taActual')}
            {renderMultiSelect('isActive', isActiveOptions, 'isActive')}
            {renderMultiSelect('City', cityOptions, 'city')}
            */}
            {renderMultiSelect('Trade Segment', tradeSegmentOptions, 'tradeSegment')}
{isKaNameActive
    ? renderMultiSelect('KA Name', kaNameOptions, 'kaName')
    : <Typography variant="body2">Select "KEY ACCOUNT" in Trade Segment to enable KA Name.</Typography>
}
{renderMultiSelect('POS Type', posTypeOptions, 'posType')}
{renderMultiSelect('Placement', placementOptions, 'placementNew')}
{renderMultiSelect('Manned', mannedOptions, 'manned')}
{renderMultiSelect('Tier', tireOptions, 'tire')}
{renderMultiSelect('Screen Type', screenTypeOptions, 'screenType')}
{renderMultiSelect('Region', regionOptions, 'region')}
{renderMultiSelect('TAL', talActualOptions, 'talActual')}
{renderMultiSelect('TA', taActualOptions, 'taActual')}
{renderMultiSelect('isActive', isActiveOptions, 'isActive')}
{renderMultiSelect('City', cityOptions, 'city')}
{renderMultiSelect('Seasonality', seasonalityOptions, 'seasonality')}
        </div>
    );

};

export default DeviceFilters;
